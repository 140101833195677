import React, { forwardRef } from 'react';

import { Checkbox } from 'antd';

import useClassNames from '~/hooks/use-classnames';

import style from './CheckboxGroup.module.scss';

const CheckboxGraphic = ({ option, checkboxClassName }) => {
    const classes = useClassNames([checkboxClassName, style[option.dataKey]]);

    return (
        <Checkbox
            key={option.key}
            value={option.key}
            className={classes}
        >
            {option.label}
        </Checkbox>
    );
};

const CheckboxGroup = forwardRef(({
    value,
    options,
    onChange,
    checkboxClassName,
    ...otherProps
}, ref) => {

    return (
        <Checkbox.Group
            ref={ref}
            value={value}
            onChange={onChange}
            {...otherProps}
        >
            {options.map(option => {
                return (
                    <CheckboxGraphic
                        checkboxClassName={checkboxClassName}
                        option={option}
                    />
                );
            })}
        </Checkbox.Group>
    );
});

export default CheckboxGroup;
