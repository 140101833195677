import React from 'react';

// import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import { history } from '~/helpers/history-helper';
import { AntLocale } from '~/helpers/locale-helper';
// import keycloak from '~/keycloak';
import store from '~/redux/store';

const ICON_PROP_VALUE = { className: 'react-icons' };

// const eventLogger = (event, error) => {
//     if (__DEV__) {
//         console.warn('onKeycloakEvent', event, error);
//     }
// };

// const tokenLogger = tokens => {
//     if (__DEV__) {
//         console.warn('onKeycloakTokens', tokens);
//     }
// };

// const KEYCLOAK_INIT_OPTIONS = {
//     pkceMethod: 'S256',
//     enableLogging: __DEV__,
//     checkLoginIframe: false,
// };

// const Providers = ({ children, ignoreKeycloak }) => {
const Providers = ({ children }) => {
    return (
        <IconContext.Provider value={ICON_PROP_VALUE}>
            <ConfigProvider locale={AntLocale}>
                <Router history={history}>
                    <ReduxProvider store={store}>
                        {children}
                    </ReduxProvider>
                </Router>
            </ConfigProvider>
        </IconContext.Provider>
    );

    // if (ignoreKeycloak) return defaultProviders;

    // return (
    //     <ReactKeycloakProvider
    //         authClient={keycloak}
    //         onEvent={eventLogger}
    //         onTokens={tokenLogger}
    //         initOptions={KEYCLOAK_INIT_OPTIONS}
    //     >
    //         {defaultProviders}
    //     </ReactKeycloakProvider>
    // );
};

Providers.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Providers;
