/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React, { useCallback } from 'react';

import { UndoOutlined, CheckOutlined, StopOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import IconButton from '~/components/IconButton';
import useAxios from '~/hooks/use-axios';

const PricingRuleActionsButton = ({ data, onOpenModal, onSubmit }) => {
    const { t } = useTranslation('UPDATE_HISTORY_PAGE');
    const axios = useAxios({
        baseURL: process.env.REACT_APP_URL_API_GO,
    });

    const { logStatus, ruleId, id: ruleLogId } = data;

    const handleUndo = useCallback(() => {
        onOpenModal(data);
    }, [data, onOpenModal]);

    const handleApprove = useCallback(async () => {
        try {
            await axios.put(`/v1/rules/${ruleId}/logs/${ruleLogId}/apply`);
            message.success(t('SUCCESS_ON_APPROVE_RULE'));
            onSubmit();
        } catch (error) {
            message.error(t('ERROR_ON_CHANGE_RULE'));
        }
    }, [axios, ruleId, ruleLogId, onSubmit, t]);

    const handleCancel = useCallback(async () => {
        try {
            await axios.put(`/v1/rules/${ruleId}/logs/${ruleLogId}/cancel`);
            message.success(t('SUCCESS_ON_CANCEL_RULE'));
            onSubmit();
        } catch (error) {
            message.error(t('ERROR_ON_CHANGE_RULE'));
        }
    }, [axios, ruleId, ruleLogId, onSubmit, t]);

    const actionButton = action => {
        return (
            <>
                <IconButton
                    onClick={() => {
                        action === t('UNDO')
                            ? handleUndo()
                            : action === t('APPROVE')
                                ? handleApprove()
                                : handleCancel();
                    }}
                    Icon={action === t('UNDO')
                        ? UndoOutlined
                        : action === t('APPROVE')
                            ? CheckOutlined
                            : StopOutlined}
                    title={`${action}`}
                />
            </>
        );
    };

    const renderButton = () => {
        switch (logStatus) {
            case 'applied':
                return actionButton(t('UNDO'));

            case 'pending':
                return (
                    <>
                        {actionButton(t('APPROVE'))}
                        {actionButton(t('CANCEL'))}
                    </>
                );
            default:
                return <></>;
        }
    };

    return <>{renderButton()}</>;
};

export default PricingRuleActionsButton;
