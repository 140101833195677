export default async (axios, searchValue, page, limit, params = {}) => {
    const response = await axios.get('/services/anyenvios/api/recipients', {
        params: {
            page,
            name: searchValue,
        },
    });

    const { data } = response;
    const { totalPages, number, content } = data;

    return {
        metadata: {
            page: number,
            pages: totalPages,
        },
        result: content
            .filter(item => item.status)
            .map(item => ({
                label: item.name,
                key: item.id,
                item,
            })),
    };
};
