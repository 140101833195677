import React from 'react';

import styles from './PageTitle.module.scss';

const PageTitle = ({
    title,
    subtitle,
}) => {
    return (
        <div>
            <div>
                {title}
            </div>
            <div className={styles.subtitle}>
                {subtitle}
            </div>
        </div>
    );
};

export default PageTitle;
