import React from 'react';

import { Form as AntForm } from 'antd';

import useClassNames from '~/hooks/use-classnames';

import styles from './Form.module.scss';

const Form = ({
    onSubmit, ...others
}) => {
    return (
        <AntForm
            {...others}
            onSubmitCapture={onSubmit}
        />
    );
};

Form.defaultProps = {
    layout: 'vertical',
};

const FormItem = ({
    className, size, children, label, required, ...others
}) => {
    const classes = useClassNames([
        {
            [styles.formItemLarge]: size === 'large',
            [styles.formItemWithLabel]: label,
        },
        styles.formItem,
        className,
    ]);

    const renderFloatingLabel = () => {
        if (!label) return null;

        return (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className={styles.floatingLabel}>
                {label} {required ? <span className={styles.floatingLabelRequired}>*</span> : null}
            </label>
        );
    };

    return (
        <AntForm.Item
            className={classes}
            required={required}
            {...others}
        >
            {children}
            {renderFloatingLabel()}
        </AntForm.Item>
    );
};

Form.Item = FormItem;

export default Form;
