import { createDecimalFormatter, maskInteger } from './mask-helper';

export const createDecimalParser = options => {
    const r = createDecimalFormatter(options);
    const masked = r.replace(/[^0-9,]+/g, '');
    const parsed = masked.replace(/[.]/g, '').replace(',', '.');
    return Number(parsed);
};

export const parseDecimal = value => {
    return createDecimalParser({
        precision: 2,
        separator: ',',
        v: value,
    });
};

export const parsePrecisionThree = value => {
    return createDecimalParser({
        precision: 3,
        separator: ',',
        v: value,
    });
};

export const parseInteger = value => {
    const stripped = maskInteger(value);
    return stripped ? Number(stripped) : '';
};

export const parseYear = momentDate => {
    return momentDate.format('YYYY');
};

export const parseDate = momentDate => {
    return momentDate.toISOString();
};
