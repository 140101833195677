import React, {
    useState,
    useCallback,
    useMemo,
    forwardRef,
    useImperativeHandle,
} from 'react';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import { extractRequestError } from '~/helpers/error-helper';
import { wrapForm } from '~/helpers/form-helper';
import { areObjectsEqual } from '~/helpers/validations';
import useDidMount from '~/hooks/use-did-mount';
import { DEFAULT_TABLE_PAGINATION } from '~/values/enums';

import Alert from '../Alert';
import FilterContainer from '../FilterContainer';
import Form from '../Form';
import Table from '../Table';

const DEFAULT_DATA_CONTENT = {
    content: [],
    size: DEFAULT_TABLE_PAGINATION.limit,
    number: DEFAULT_TABLE_PAGINATION.page - 1,
    totalElements: DEFAULT_TABLE_PAGINATION.total,
    numberOfElements: DEFAULT_TABLE_PAGINATION.count,
};

const getOrderParam = sorter => {
    if (sorter.column && sorter.order) {
        return {
            order: sorter.order,
            field: sorter.column.dataSorter,
        };
    }

    return null;
};

const getColumnWithOrders = (column, orders) => {
    if (!column.props.dataSorter) return column;

    const sortOrder = orders?.field === column.props.dataSorter ? orders.order : false;
    return React.cloneElement(column, {
        sortOrder,
        sorter: true,
    });
};

const SimpleListContent = forwardRef(({
    handleSubmit, form,
    children, defaultFilters,
    tableRowKey, filterInputs,
    requestDataSource, loading,
    defaultOrders, tableScroll,
    ...otherProps
}, $ref) => {
    const [dataSource, setDataSource] = useState([]);
    const [requesting, setRequesting] = useState(false);
    const [requestParams, setRequestParams] = useState({});
    const [tablePagination, setTablePagination] = useState(DEFAULT_TABLE_PAGINATION);

    const { error, submitError } = form.getState();

    const filteredChildren = useMemo(() => {
        const { orders } = requestParams;

        return React.Children.toArray(children)
            .filter(Boolean)
            .map(column => getColumnWithOrders(column, orders));
    }, [children, requestParams]);

    const sendDataSourceRequest = useCallback(async (request, pagination) => {
        try {
            setRequesting(true);
            const { filters = {}, orders = {} } = request;
            const { page, limit } = pagination;

            let data;
            try {
                const response = await requestDataSource(
                    filters,
                    orders,
                    page - 1,
                    limit,
                );

                if (!response) return null;

                data = response.data;
            } catch (err) {
                if (!err.response?.status === 404) throw err;

                data = DEFAULT_DATA_CONTENT;
            }

            const {
                number, numberOfElements,
                content, totalElements, size,
            } = data;

            setTablePagination({
                limit: size,
                page: number + 1,
                total: totalElements,
                count: numberOfElements,
            });
            setDataSource(content);
        } catch (err) {
            return await extractRequestError(err);
        } finally {
            setRequesting(false);
        }

        return null;
    }, [requestDataSource]);

    const onTableChange = useCallback(async (changedPagination, filters, sorter) => {
        const pagination = {
            ...tablePagination,
            page: changedPagination.current,
            limit: changedPagination.pageSize,
        };

        const params = {
            ...requestParams,
            orders: getOrderParam(sorter),
        };

        setTablePagination(pagination);
        setRequestParams(params);

        const response = await sendDataSourceRequest(params, pagination);
        return response;
    }, [tablePagination, requestParams, sendDataSourceRequest]);

    useDidMount(() => {
        setTimeout(() => {
            form.initialize(defaultFilters || {});
            form.submit();
        }, 0);
    });

    const hasChangedFilters = useMemo(() => {
        return !areObjectsEqual(requestParams.filters, defaultFilters);
    }, [requestParams.filters, defaultFilters]);

    const onFilterSubmit = useCallback(async values => {
        const params = {
            ...requestParams,
            filters: values,
        };

        const pagination = {
            ...tablePagination,
            page: 1,
            limit: tablePagination.limit,
        };

        setRequestParams(params);
        setTablePagination(pagination);

        const response = await sendDataSourceRequest(params, pagination);
        return response;
    }, [requestParams, tablePagination, sendDataSourceRequest]);

    const handleClearFilters = useCallback(async () => {
        const params = {
            filters: defaultFilters,
            orders: defaultOrders,
        };

        setTablePagination(DEFAULT_TABLE_PAGINATION);
        setRequestParams(params);
        form.initialize(defaultFilters || {});

        const response = await sendDataSourceRequest(params, DEFAULT_TABLE_PAGINATION);
        return response;
    }, [defaultFilters, defaultOrders, sendDataSourceRequest, form]);

    useImperativeHandle(
        $ref,
        () => ({
            refresh: () => sendDataSourceRequest(requestParams, tablePagination),
            refilter: filters => {
                const newFilters = {
                    ...defaultFilters,
                    ...filters,
                };
                form.initialize(newFilters);
                return form.submit();
            },
            getFilters: () => requestParams.filters,
            getOrders: () => requestParams.orders,
        }),
        [defaultFilters, form, requestParams, sendDataSourceRequest, tablePagination],
    );

    const renderFilters = () => {
        if (!filterInputs) return null;

        const filteredInputs = filterInputs.map(input => (
            <Col {...input.column} key={input.name}>
                {input.component}
            </Col>
        ));

        const showClearButton = hasChangedFilters && filteredInputs.length;

        return (
            <FilterContainer
                loading={requesting}
                onClearClick={showClearButton ? handleClearFilters : null}
            >
                <Row gutter={24} align="middle">
                    {filteredInputs}
                </Row>
            </FilterContainer>
        );
    };

    return (
        <div>
            <Alert type="error" message={error || submitError} />
            <Form onSubmit={handleSubmit(onFilterSubmit)}>
                {renderFilters()}
            </Form>
            <Table
                {...otherProps}
                rowKey={tableRowKey}
                scroll={tableScroll}
                dataSource={dataSource}
                onChange={onTableChange}
                page={tablePagination.page}
                limit={tablePagination.limit}
                total={tablePagination.total}
                loading={requesting || loading}
            >
                {filteredChildren}
            </Table>
        </div>
    );
});

SimpleListContent.propTypes = {
    loading: PropTypes.bool,
    requestDataSource: PropTypes.func.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    defaultOrders: PropTypes.shape({
        field: PropTypes.string.isRequired,
        order: PropTypes.oneOf(['descend', 'ascend']).isRequired,
    }),
    tableRowKey: PropTypes.func,
    tableScroll: PropTypes.object,
};

SimpleListContent.defaultProps = {
    loading: false,
    defaultFilters: {},
    filterInputs: undefined,
    defaultOrders: undefined,
    tableRowKey: item => item.id,
    tableScroll: {
        x: 1024,
    },
};

export default wrapForm(SimpleListContent);
