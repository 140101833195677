import React, {
    lazy, forwardRef, memo, useCallback, useMemo,
} from 'react';

import {
    Input as AntInput, Switch, Checkbox,
} from 'antd';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import { wrapFormField } from '~/helpers/form-helper';

import CheckboxGroup from './CheckboxGroup';
import Form from './Form';
import RadioGroup from './RadioGroup';
import TextHelper from './TextHelper';

const DatePicker = lazy(() => import('./DatePicker'));
const Select = lazy(() => import('./Select'));
const DateRangePicker = lazy(() => import('./DateRangePicker'));

const { TextArea } = AntInput;
const { Item } = Form;

const Input = memo(forwardRef((props, ref) => {
    const {
        input, meta, size,
        label, formItemProps,
        required, hasFeedback,
        hasFormItem, charCounter, maxLength, tip,
        errorMessage, help, width, minWidth, maxWidth,
    } = props;
    const { onChange, type, value } = input;
    const {
        valid, touched, error, submitError,
    } = meta;

    const handleDateChange = useCallback(newValue => {
        onChange(newValue);
    }, [onChange]);

    const getValidateStatus = () => {
        if (!touched) return null;

        return valid ? 'success' : 'error';
    };

    const inputRenders = {
        text: inputProps => {
            return (
                <AntInput
                    {...inputProps}
                    {...input}
                    ref={ref}
                />
            );
        },
        textarea: inputProps => {
            return (
                <TextArea
                    {...inputProps}
                    {...input}
                    ref={ref}
                />
            );
        },
        date: inputProps => {
            const {
                dateFormat,
                ...others
            } = inputProps;
            return (
                <DatePicker
                    {...others}
                    {...input}
                    ref={ref}
                    onChange={handleDateChange}
                    format={dateFormat}
                />
            );
        },
        select: inputProps => {
            return (
                <Select
                    {...inputProps}
                    {...input}
                    ref={ref}
                />
            );
        },
        daterange: inputProps => {
            const {
                dateFormat,
                ...others
            } = inputProps;
            return (
                <DateRangePicker
                    {...others}
                    {...input}
                    ref={ref}
                    onChange={handleDateChange}
                    format={dateFormat}
                />
            );
        },
        switch: inputProps => {
            return (
                <Switch
                    {...inputProps}
                    {...input}
                    ref={ref}
                    value={null}
                    checked={value || false}
                />
            );
        },
        checkbox: inputProps => {
            return (
                <Checkbox
                    {...inputProps}
                    {...input}
                    ref={ref}
                    checked={value || false}
                />
            );
        },
        checkboxGroup: inputProps => {
            return (
                <CheckboxGroup
                    {...inputProps}
                    {...input}
                    ref={ref}
                />
            );
        },
        radioGroup: inputProps => {
            return (
                <RadioGroup
                    {...inputProps}
                    {...input}
                    ref={ref}
                />
            );
        },
    };

    const renderInputComponent = () => {
        const inputProps = omit(props, [
            'label', 'formItemProps', 'charCounter',
            'hasFormItem', 'meta', 'input', 'parser',
            'hasFeedback', 'width', 'minWidth', 'maxWidth',
        ]);

        const render = inputRenders[type] || inputRenders.text;

        return render(inputProps);
    };

    const formItemStyle = useMemo(() => ({
        width,
        maxWidth,
        minWidth,
        ...formItemProps?.style,
    }), [formItemProps?.style, maxWidth, minWidth, width]);

    if (!hasFormItem) return renderInputComponent();

    const renderHelp = () => {
        if (touched && !valid) {
            return errorMessage || error || submitError;
        }

        if (charCounter) {
            const charCounterMessage = charCounter
                ? `${(value || '').length} / ${maxLength}`
                : null;
            return charCounterMessage;
        }

        return help;
    };

    const renderLabel = () => {
        if (tip && label) {
            return (
                <TextHelper tooltipTitle={tip}>
                    <span>{label} </span>
                </TextHelper>
            );
        }

        return label;
    };

    return (
        <Item
            label={renderLabel()}
            help={renderHelp()}
            hasFeedback={!meta.active && meta.touched && hasFeedback}
            validateStatus={getValidateStatus()}
            required={required}
            size={size}
            style={formItemStyle}
            {...formItemProps}
        >
            {renderInputComponent()}
        </Item>
    );

}));

Input.propTypes = {
    meta: PropTypes.object,
    input: PropTypes.object.isRequired,
    hasFormItem: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
};

Input.defaultProps = {
    meta: {},
    input: {},
    hasFormItem: true,
    size: 'large',
};

Input.Field = wrapFormField(Input);

export default Input;
