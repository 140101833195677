import React, {
    memo, useMemo, useCallback,
} from 'react';

import { Table as AntTable } from 'antd';
import PropTypes from 'prop-types';

import useClassNames from '~/hooks/use-classnames';
import { DEFAULT_PAGE_SIZE } from '~/values/enums';
import strings from '~/values/strings';

import { itemRender } from './Pagination';
import styles from './Table.module.scss';

const { Column, ColumnGroup } = AntTable;

const DEFAULT_PAGINATION_PROPS = {
    size: 'small',
    showSizeChanger: true,
    pageSize: DEFAULT_PAGE_SIZE,
    pageSizeOptions: [DEFAULT_PAGE_SIZE, 30, 50, 100, 150, 200],
    itemRender,
};

const Table = memo(({
    page, total, limit,
    loading, bodyStyle,
    children, pagination,
    className, onChange,
    ...others
}) => {
    const shouldShowPagination = page || limit || total;

    const tableClasses = useClassNames([
        styles.antTable,
        className,
    ]);

    const paginationConfig = useMemo(() => {
        if (pagination === true) {
            return DEFAULT_PAGINATION_PROPS;
        }

        if (pagination === false) {
            return false;
        }

        return shouldShowPagination ? {
            ...DEFAULT_PAGINATION_PROPS,
            current: page,
            pageSize: limit || DEFAULT_PAGE_SIZE,
            total,
        } : false;
    }, [limit, page, pagination, shouldShowPagination, total]);

    const loadingConfig = useMemo(() => {
        return {
            spinning: loading,
            size: 'large',
            tip: strings.tableLoadingTip,
        };
    }, [loading]);

    const bodyStyles = useMemo(() => ({
        ...bodyStyle,
        overflowX: 'auto',
    }), [bodyStyle]);

    const rowClassName = useCallback((_, index) => {
        return index % 2 === 0 ? styles.lightRowTable : styles.darkRowTable;
    }, []);

    const renderColumns = () => {
        return React.Children.map(children, column => {
            if (!column) return null;

            const { render } = column.props;

            const renderWrap = (value, record) => {
                let content;
                if (render) {
                    content = render(value, record);
                } else {
                    content = value;
                }
                return content;
            };

            return React.cloneElement(column, {
                render: renderWrap,
            });
        });
    };

    return (
        <AntTable
            rowClassName={rowClassName}
            {...others}
            size="small"
            bodyStyle={bodyStyles}
            pagination={paginationConfig}
            loading={loadingConfig}
            className={tableClasses}
            onChange={onChange}
            sticky
        >
            {renderColumns()}
        </AntTable>
    );
});

Table.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    loading: PropTypes.bool,
};

Table.defaultProps = {
    page: null,
    limit: null,
    total: null,
    loading: false,
    scroll: {
        x: 800,
    },
    rowKey: record => record?.id,
};

Table.Column = Column;
Table.ColumnGroup = ColumnGroup;

export default Table;
