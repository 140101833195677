import moment from 'moment-timezone';

import i18n from '~/translations';

import { removeMask } from './mask-helper';

const CPF_BLACKLIST = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
    '12345678909',
];

const CNPJ_BLACKLIST = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
];

/* eslint-disable max-len */
const REGEX_URL = /^https?:\/\/([\w-.:]+)(\/?|\/.+)$/;
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_CEP = /^[0-9]{8}$/;
const REGEX_TELEFONE = /^[0-9]{10,11}$/;
/* eslint-enable */

// Marketplace Links - Regex
const MARKETPLACE_LINKS_REGEX = {
    MERCADO_LIVRE_PRODUTO_REGEX: /^(https?:\/\/)?produto\.mercadolivre\.com\.br(\/.*)?/,
    MERCADO_LIVRE_REGEX: /^(https?:\/\/)?(www\.)?(mercadolivre\.com\.br|mercadolibre\.com\.mx)(\/.*)?/,
    AMAZON_REGEX: /^(https?:\/\/)?(www\.)?amazon\.com(\.br|\.mx)(\/.*)?/,
    MAGAZINE_VOCE_REGEX: /^(https?:\/\/)?(www\.)?magazinevoce\.com\.br(\/.*)?/,
    MAGAZINE_LUIZA_REGEX: /^(https?:\/\/)?(www\.)?magazineluiza\.com\.br(\/.*)?/,
};

export const validateMarketplaceLinkRegex = link => {
    return Object.keys(MARKETPLACE_LINKS_REGEX).some(regex => MARKETPLACE_LINKS_REGEX[regex].test(link));
};

export const validateName = value => {
    if (typeof value === 'string') {
        const parts = value.trim().split(' ');
        if (parts.length >= 2) {
            const partsAreValid = parts.reduce((valid, part) => (
                valid && part.length >= 2
            ), true);
            if (partsAreValid) {
                return undefined;
            }
        }
    }
    return i18n.t('VALIDATIONS:VALIDATE_COMPLETE_NAME');
};

export const validateCheckbox = value => {
    return value ? undefined : i18n.t('VALIDATIONS:VALIDATE_CHECK_BOX');
};

export const validateEmail = value => (
    REGEX_EMAIL.test(value) ? undefined : i18n.t('VALIDATIONS:VALIDATE_EMAIL')
);

export const validateNotEmpty = value => {
    let valid;
    if (typeof value === 'string') {
        valid = Boolean(value.trim());
    } else if (Array.isArray(value)) {
        valid = Boolean(value.length);
    } else {
        valid = Boolean(value);
    }
    return valid ? undefined : i18n.t('VALIDATIONS:VALIDATE_NOT_EMPTY');
};

export const validatePassword = password => {
    const minLength = /.{8,}/;
    const lowercase = /[a-z]/;
    const uppercase = /[A-Z]/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const number = /[0-9]/;

    if (!minLength.test(password)) {
        return i18n.t('VALIDATIONS:VALIDATE_PASSWORD_MIN_LENGTH');
    }
    if (!lowercase.test(password)) {
        return i18n.t('VALIDATIONS:VALIDATE_PASSWORD_LOWERCASE');
    }
    if (!uppercase.test(password)) {
        return i18n.t('VALIDATIONS:VALIDATE_PASSWORD_UPPERCASE');
    }
    if (!specialChar.test(password)) {
        return i18n.t('VALIDATIONS:VALIDATE_PASSWORD_SPECIAL_CHAR');
    }
    if (!number.test(password)) {
        return i18n.t('VALIDATIONS:VALIDATE_PASSWORD_NUMBER');
    }

    return '';
};

const isValidNumber = value => {
    switch (typeof value) {
        case 'number':
            return Number.isFinite(value);
        case 'string':
            return /^[0-9,.]+$/.test(value);
        default:
            return false;
    }
};

export const validateNumber = value => {
    return isValidNumber(value) ? undefined : i18n.t('VALIDATIONS:VALIDATE_NUMBER');
};

const verifierDigitCPF = value => {
    const numbers = value
        .split('')
        .map(number => Number(number));
    const modulus = numbers.length + 1;
    const multiplied = numbers.map((number, index) => number * (modulus - index));
    const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;
    return (mod < 2 ? 0 : 11 - mod);
};

export const validateCPF = value => {
    const stripped = (value || '').replace(/[^\d]/g, '');

    if (stripped && stripped.length === 11 && CPF_BLACKLIST.indexOf(stripped) === -1) {
        let numbers = stripped.substr(0, 9);
        numbers += verifierDigitCPF(numbers);
        numbers += verifierDigitCPF(numbers);

        if (numbers.substr(-2) === stripped.substr(-2)) {
            return undefined;
        }
    }
    return i18n.t('VALIDATIONS:VALIDATE_CPF');
};

export const validateUrl = value => (
    REGEX_URL.test(value) ? undefined : i18n.t('VALIDATIONS:VALIDATE_URL')
);

export const validateDate = value => {
    return moment(value, i18n.t('VALIDATIONS:VALIDATE_FORMAT_DATE'), true).isValid()
        ? undefined
        : i18n.t('VALIDATIONS:VALIDATE_DATE');
};

export const validateCep = value => (
    REGEX_CEP.test(removeMask(value))
        ? undefined
        : i18n.t('VALIDATIONS:VALIDATE_CEP')
);

export const validatePhone = value => (
    REGEX_TELEFONE.test(removeMask(value))
        ? undefined
        : i18n.t('VALIDATIONS:VALIDATE_PHONE')
);

const verifierDigitCNPJ = numbers => {
    const reverse = numbers.split('').reduce((buffer, number) => (
        [Number(number), ...buffer]
    ), []);

    let index = 2;
    const sum = reverse.reduce((buffer, number) => {
        const result = buffer + (number * index);
        index = (index === 9 ? 2 : index + 1);
        return result;
    }, 0);

    const mod = sum % 11;
    return (mod < 2 ? 0 : 11 - mod);
};

export const validateCNPJ = value => {

    const stripped = (value || '').replace(/[^\d]/g, '');

    if (stripped && stripped.length === 14 && CNPJ_BLACKLIST.indexOf(stripped) === -1) {
        let numbers = stripped.substr(0, 12);
        numbers += verifierDigitCNPJ(numbers);
        numbers += verifierDigitCNPJ(numbers);

        if (numbers.substr(-2) === stripped.substr(-2)) {
            return undefined;
        }
    }

    return i18n.t('VALIDATIONS:VALIDATE_CNPJ');
};

export const validateCPFCNPJ = value => {
    // 09963443907
    // 04294897002701
    const stripped = (value || '').replace(/[^\d]/g, '');
    if (stripped.length <= 11) {
        if (stripped && stripped.length === 11 && CPF_BLACKLIST.indexOf(stripped) === -1) {
            let numbers = stripped.substr(0, 9);
            numbers += verifierDigitCPF(numbers);
            numbers += verifierDigitCPF(numbers);

            if (numbers.substr(-2) === stripped.substr(-2)) {
                return undefined;
            }
        }

    } else if (stripped.length > 11 && stripped.length <= 14) {
        if (stripped && stripped.length === 14 && CNPJ_BLACKLIST.indexOf(stripped) === -1) {
            let numbers = stripped.substr(0, 12);
            numbers += verifierDigitCNPJ(numbers);
            numbers += verifierDigitCNPJ(numbers);

            if (numbers.substr(-2) === stripped.substr(-2)) {
                return undefined;
            }
        }
    }

    return i18n.t('VALIDATIONS:VALIDATE_CPF_CNPJ');
};

export const validateCreditValue = value => {
    if (value >= 1) {
        return undefined;
    }

    return i18n.t('VALIDATIONS:VALIDATE_CREDIT_VALUE');
};

export const areObjectsEqual = (object1, object2) => {
    const obj1 = object1 || {};
    const obj2 = object2 || {};
    /* eslint-disable no-prototype-builtins, no-restricted-syntax, guard-for-in, eqeqeq */

    // eslint-disable-next-line no-unused-vars
    for (const p in obj1) {
        switch (typeof obj1[p]) {
            case 'object':
                if (!areObjectsEqual(obj1[p], obj2[p])) return false;
                break;
            default:
                if (obj1[p] != obj2[p]) return false;
        }
    }

    // eslint-disable-next-line no-unused-vars
    for (const p in obj2) {
        switch (typeof obj2[p]) {
            case 'object':
                if (!areObjectsEqual(obj2[p], obj1[p])) return false;
                break;
            default:
                if (obj1[p] != obj2[p]) return false;
        }
    }

    /* eslint-enable */
    return true;
};

export const validateSpaces = value => {
    if (value) {
        if (typeof value === 'string') {
            if (value.includes(' ')) {
                return i18n.t('VALIDATIONS:VALIDATE_SPACES');
            }
        }
    }
    return undefined;
};

export const validateNotZero = value => {
    if (typeof value === 'number') {
        if (value === 0) {
            return i18n.t('VALIDATIONS:VALIDATE_NOT_ZERO');
        }
    }
    return undefined;
};
