/* eslint-disable react/no-array-index-key */
import React from 'react';

import { useTranslation } from 'react-i18next';

const NotificationCard = ({ data }) => {
    const { t } = useTranslation('NOTIFICATION');

    const content = (
        <div>
            <p>{t('NOTIFICATION_CARD')}</p>
        </div>
    );

    return <div>{content}</div>;
};

export default NotificationCard;
