export default async (axios, searchValue, page, limit, params = {}) => {
    const response = await axios.get('/campaigns', {
        params: {
            ...params,
            page,
            limit,
            status: 'ENABLED',
            name: searchValue,
        },
    });

    const { data } = response;
    const { totalPages, number, content } = data;

    return {
        metadata: {
            page: number,
            pages: totalPages,
        },
        result: content.map(item => ({
            label: item.name,
            key: item.id,
            item,
        })),
    };
};
