import React, { memo } from 'react';

import { Spin } from 'antd';
import PropTypes from 'prop-types';

import useClassNames from '~/hooks/use-classnames';

import styles from './Spinner.module.scss';

const Spinner = memo(({ className, ...others }) => {
    const wrapperClasses = useClassNames([styles, className]);
    return (
        <Spin
            size="large"
            className={styles.customspin}
            wrapperClassName={wrapperClasses}
            {...others}
        />
    );
});

Spinner.propTypes = {
    spinning: PropTypes.bool,
};

Spinner.defaultProps = {
    spinning: false,
};

export default Spinner;
