import React, { useState, useCallback } from 'react';

import { Tag, Tooltip } from 'antd';

import i18n from '~/translations';

import { MARKETPLACES_ID, CAMPAIGN_STRATEGY, RECOMMENDATION_TAGS } from '../../values/enums';
import styles from './AdTag.module.scss';

const CHANGE_STRATEGY_AMAZON = i18n.t('AD_TAG:CHANGE_STRATEGY_AMAZON');

const MERCADO_LIVRE_RENTABILITY = i18n.t('AD_TAG:MERCADO_LIVRE_RENTABILITY');

const MERCADO_LIVRE_INCREASE = i18n.t('AD_TAG:MERCADO_LIVRE_INCREASE');

const MERCADO_LIVRE_VISIBILITY = i18n.t('AD_TAG:MERCADO_LIVRE_VISIBILITY');

const HIGH_CPC_AMAZON = i18n.t('AD_TAG:HIGH_CPC_AMAZON');

const HIGH_CPC_MERCADO_LIVRE = i18n.t('AD_TAG:HIGH_CPC_MERCADO_LIVRE');

export const TAG_INFORMATION = {
    READY_TO_INVEST: i18n.t('AD_TAG:READY_TO_INVEST'),

    LOSING_BUYBOX: i18n.t('AD_TAG:LOSING_BUYBOX'),

    IMPROVE_HEALTH: i18n.t('AD_TAG:IMPROVE_HEALTH'),

    NOT_ATTRACTIVE: i18n.t('AD_TAG:NOT_ATTRACTIVE'),

    LOW_CONVERSION: i18n.t('AD_TAG:LOW_CONVERSION'),

    HIGH_CONSUMPTION: i18n.t('AD_TAG:HIGH_CONSUMPTION'),

    DOING_WELL: i18n.t('AD_TAG:DOING_WELL'),

    ACOS_GREATER_OBJECTIVE: i18n.t('AD_TAG:ACOS_GREATER_OBJECTIVE'),
};

const CUSTOM_TAG_INFORMATION = {
    HIGH_CPC: {
        [MARKETPLACES_ID.AMAZON]: HIGH_CPC_AMAZON,
        [MARKETPLACES_ID.MERCADO_LIVRE]: HIGH_CPC_MERCADO_LIVRE,
    },
    CHANGE_STRATEGY: {
        [MARKETPLACES_ID.AMAZON]: CHANGE_STRATEGY_AMAZON,
        [MARKETPLACES_ID.MERCADO_LIVRE]: {
            [CAMPAIGN_STRATEGY.RENTABILITY]: MERCADO_LIVRE_RENTABILITY,
            [CAMPAIGN_STRATEGY.PROFITABILITY]: MERCADO_LIVRE_RENTABILITY,
            [CAMPAIGN_STRATEGY.INCREASE]: MERCADO_LIVRE_INCREASE,
            [CAMPAIGN_STRATEGY.VISIBILITY]: MERCADO_LIVRE_VISIBILITY,
        },
    },
};

export const TAGS_OPTIONS_WITHOUT_ADS = [
    {
        key: 'READY_TO_INVEST',
        label: i18n.t('AD_TAG:READY_TO_INVEST_LABEL'),
    },
    {
        key: 'IMPROVE_HEALTH',
        label: i18n.t('AD_TAG:IMPROVE_HEALTH_LABEL'),
    },
    {
        key: 'LOSING_BUYBOX',
        label: i18n.t('AD_TAG:LOSING_BUYBOX_LABEL'),
    },
];

export const STATUS_LISTING = [
    {
        key: 'ARCHIVED',
        label: i18n.t('AD_TAG:ARCHIVED'),
    },
    {
        key: 'ENABLED',
        label: i18n.t('AD_TAG:ENABLED'),
    },
    {
        key: 'INACTIVE',
        label: i18n.t('AD_TAG:INACTIVE'),
    },
    {
        key: 'PAUSED',
        label: i18n.t('AD_TAG:PAUSED'),
    },
    {
        key: 'REVIEW',
        label: i18n.t('AD_TAG:REVIEW'),
    },
];

export const LISTING_TAGS = [
    {
        key: 'a40a02e8-22fe-43f9-9e4a-a48bf13a6f56',
        url: '',
        tag: 'ACOS_GREATER_OBJECTIVE',
        title: i18n.t('AD_TAG:ACOS_GREATER_OBJECTIVE_LABEL'),
        color: 'blue',
    },
    {
        key: 'ed320976-04be-4e8f-aec7-9f922d37a137',
        url: '',
        tag: 'READY_TO_INVEST',
        title: i18n.t('AD_TAG:READY_TO_INVEST_LABEL'),
        color: 'magenta',
    },
    {
        key: '92a03787-3b63-4b9a-8447-462c8ebef5b4',
        url: '',
        tag: 'CHANGE_STRATEGY',
        title: i18n.t('AD_TAG:CHANGE_STRATEGY_LABEL'),
        color: 'orange',
    },
    {
        key: '532ca614-5225-4971-a532-8d393d0de951',
        url: '',
        tag: 'LOSING_BUYBOX',
        title: i18n.t('AD_TAG:LOSING_BUYBOX_LABEL'),
        color: 'red',
    },
    {
        key: 'c4655f80-7a9a-49c2-9fb5-68c9a96ac759',
        url: '',
        tag: 'IMPROVE_HEALTH',
        title: i18n.t('AD_TAG:IMPROVE_HEALTH_LABEL'),
        color: 'orange',
    },
    {
        key: '6a1b6e89-9f4d-4f8c-b56d-a60265d3a82f',
        url: '',
        tag: 'NOT_ATTRACTIVE',
        title: i18n.t('AD_TAG:NOT_ATTRACTIVE_LABEL'),
        color: 'volcano',
    },
    {
        key: 'a8e5ac4d-2e4f-427b-8c3a-91dfedbc3b1c',
        url: '',
        tag: 'HIGH_CPC',
        title: i18n.t('AD_TAG:HIGH_CPC_LABEL'),
        color: 'volcano',
    },
    {
        key: 'f2c08e56-1859-4b34-b88a-8499c9d9b914',
        url: '',
        tag: 'LOW_CONVERSION',
        title: i18n.t('AD_TAG:LOW_CONVERSION_LABEL'),
        color: 'blue',
    },
    {
        key: 'e6f285cb-ea11-46d8-943d-49ee8b8d8e89',
        url: '',
        tag: 'HIGH_CONSUMPTION',
        title: i18n.t('AD_TAG:HIGH_CONSUMPTION_LABEL'),
        color: 'blue',
    },
    {
        key: '3b46d09d-4cfd-4fe7-ba53-8783bca134de',
        url: '',
        tag: 'DOING_WELL',
        title: i18n.t('AD_TAG:DOING_WELL_LABEL'),
        color: 'green',
    },
];

export function getTagInformation(tagData) {
    const {
        strategy, marketplaceId, tagString, healthScore,
    } = tagData;
    let tagInformation = '';

    switch (tagString) {
        case 'HIGH_CPC':
            tagInformation = CUSTOM_TAG_INFORMATION.HIGH_CPC[marketplaceId] || '';
            break;
        case 'CHANGE_STRATEGY':
            tagInformation = CUSTOM_TAG_INFORMATION.CHANGE_STRATEGY[marketplaceId]?.[strategy] || '';
            break;
        default:
            tagInformation = TAG_INFORMATION[tagString] || '';
    }

    return tagInformation.replace(/%HEALTH/g, healthScore);
}

export function getRecommendationTag(tagString) {
    const recommendation = LISTING_TAGS.find(item => item.tag === tagString);
    return recommendation || '';
}

export const AdTag = ({
    tag, title, information, color, onClick,
}) => {
    const [tooltipInformation, setTooltipInformation] = useState(false);

    const visibleTooltipInformation = useCallback(visible => {
        setTooltipInformation(visible);
    }, []);

    const tagStyle = {
        marginBottom: '3px',
        color,
        padding: '2px 5px',
        borderRadius: '0px',
        width: '120px',
        cursor: 'context-menu',
    };

    if (tag === RECOMMENDATION_TAGS.LOSING_BUYBOX || tag === RECOMMENDATION_TAGS.LOW_CONVERSIO) {
        tagStyle.cursor = 'pointer';
    }

    const tagProps = {
        style: tagStyle,
        color,
        onClick,
    };

    const InformationDisplayItem = () => {
        return (
            <>
                <h2>{title}</h2>
                <p className={styles.tooltipInformationCard}>{information}</p>
            </>
        );
    };

    const hasTooltip = information && information !== '';
    return (
        !hasTooltip ? (
            <Tag {...tagProps}>{title}</Tag>
        ) : (
            <Tooltip
                title={<InformationDisplayItem />}
                visible={tooltipInformation}
                onVisibleChange={visibleTooltipInformation}
                color="white"
                overlayClassName={styles.tooltipInformationCard}
            >
                <Tag {...tagProps}>{title}</Tag>
            </Tooltip>
        )
    );

};
