import { Ability } from '@casl/ability';
import { handleAction, createAction, handleActions } from 'redux-actions';

const setUser = createAction('USERS/SET_USER');
const updateUser = createAction('USERS/UPDATE_USER');
const logoutUser = createAction('USERS/LOGOUT');
const setToken = createAction('USERS/SET_TOKEN');
const setOrganization = createAction('USERS/SET_ORGANIZATION');
const setAbility = createAction('USERS/SET_ABILITY');
const setSkipRefreshToken = createAction('USERS/SET_SKIP_REFRESH_TOKEN');
const setIsNewSender = createAction('COMPANY/SET_NEWSENDER');

export const actions = {
    setUser,
    logoutUser,
    setToken,
    setOrganization,
    setAbility,
    updateUser,
    setSkipRefreshToken,
    setIsNewSender,
};

export const userHandler = handleActions({
    [setUser]: (state, action) => ({
        ...action.payload,
    }),
    [updateUser]: (state, action) => ({
        ...state,
        ...action.payload,
    }),
}, null);

export const tokenHandler = handleAction(
    setToken,
    (state, action) => action.payload,
    null,
);

export const organizationHandler = handleAction(
    setOrganization,
    (state, action) => action.payload,
    null,
);

export const isNewSenderHandler = handleAction(
    setIsNewSender,
    (state, action) => action.payload,
    null,
);

export const abilityHandler = handleAction(
    setAbility,
    (state, action) => action.payload,
    new Ability([]),
);

export const skipRefreshTokenHandler = handleAction(
    setSkipRefreshToken,
    (state, action) => action.payload || false,
    false,
);

export const reducers = {
    user: userHandler,
    token: tokenHandler,
    organization: organizationHandler,
    ability: abilityHandler,
    skipRefreshToken: skipRefreshTokenHandler,
    setIsNewSender: isNewSenderHandler,
};
