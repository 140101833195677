import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translations from './languages';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnNull: false,
        compabilityJSON: 'v3',
        fallbackLng: 'pt',
        resources: translations,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: [
                'localStorage',
                'querystring',
                'navigator',
                'htmlTag',
            ],
        },
    });

export default i18n;
