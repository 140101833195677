/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, {
    memo, useCallback, useMemo,
} from 'react';

import { ShopOutlined } from '@ant-design/icons';
import {
    Layout,
    Popover, Divider,
    message,
    Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Select from '~/components/Select';
import UserMenu from '~/components/UserMenu';
import browserStorageHelper from '~/helpers/browser-storage-helper';
import historyHelper from '~/helpers/history-helper';
import { connectScreenSize } from '~/helpers/screen-size-helper';
import useScreen from '~/hooks/use-screen';
import thunks from '~/redux/thunks';
import { LANGUAGE_OPTIONS } from '~/values/enums';

import NotificationWidget from '../notification/NotificationWidget';
import styles from './AppHeaderBar.module.scss';
import MenuButton from './MenuButton';

const { Header } = Layout;

const AppHeaderBar = memo(({
    history,
    removeAuthenticationData,
    user,
}) => {
    const { sizes: { isDesktop } } = useScreen();
    const { t, i18n } = useTranslation('APP_HEADER_BAR');

    const selectedLanguage = useMemo(() => LANGUAGE_OPTIONS.getItemByKey(
        t('GLOBAL:ACCEPTED_LANGUAGE_HEADER'),
    ), [t]);

    const {
        name,
        companyId,
        companyName,
        token,
    } = user;

    const handleLogout = useCallback(() => {
        removeAuthenticationData();
    }, [removeAuthenticationData]);

    const renderUserOptions = () => {
        return (
            <div className={styles.profile}>
                <b>{t('OPTIONS')}</b>
                <Divider className={styles.optionDivider} />
                <a onClick={handleLogout} role="button" tabIndex={0}>{t('EXIT')}</a>
            </div>
        );
    };

    const handleLanguageChange = useCallback(language => {
        i18n.changeLanguage(language.key, err => {
            if (err) {
                message.error(t('CHANGE_LANGUAGE_ERROR'));
            } else {
                browserStorageHelper.clearFilters();
                // TODO: reset das colunas salvas no local storage enquanto as colunas forem salvas pela tradução
                // e não outra chave
                browserStorageHelper.clearColumns();
                historyHelper.clearQueries();
                window.location.reload();
            }
        });
    }, [i18n, t]);

    const changeCompany = useCallback(() => {
        handleLogout();
        history.push(`/companies?token=${token}`);
    }, [history, handleLogout, token]);

    return (
        <Header className={styles.header}>
            <div className={styles.headerContainer}>
                <NotificationWidget companyId={companyId} />

                <Divider type="vertical" className={styles.menuDivider} />
                {isDesktop ? null : (<MenuButton className={styles.menuButton} />)}
                <div className={styles.avatarContainer}>
                    <Select
                        value={selectedLanguage}
                        options={LANGUAGE_OPTIONS}
                        onChange={handleLanguageChange}
                        className={styles.languageSelect}
                    />
                </div>
                <div className={styles.avatarContainer}>
                    <Divider type="vertical" className={styles.menuDivider} />
                    <Tooltip title={t('CHANGE_COMPANY')}>
                        <div className={styles.changeCompany} onClick={() => changeCompany()}>
                            <ShopOutlined />&nbsp;{companyName}
                        </div>
                    </Tooltip>
                </div>
                <div className={styles.avatarContainer}>
                    <Divider type="vertical" className={styles.menuDivider} />
                    <Popover
                        placement="bottomRight"
                        content={renderUserOptions()}
                        trigger="focus"
                    >
                        <a onClick={null} role="button" tabIndex="-1">
                            <UserMenu
                                name={name}
                                photo={null}
                            />
                        </a>
                    </Popover>
                </div>
            </div>
        </Header>
    );
});

AppHeaderBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = ({ user }) => ({
    user,
});

const mapDispatchToProps = ({
    removeAuthenticationData: thunks.user.removeAuthenticationData,
});

export default connect(mapStateToProps, mapDispatchToProps)(connectScreenSize(withRouter(AppHeaderBar)));
