// BOM é necessário para que o excel entenda que o arquivo
// está no formato UTF-8
// https://stackoverflow.com/a/42466254/2826279
const universalBOM = '\uFEFF';

export const downloadFile = ({ filename, content, contentType }) => {
    const blob = new Blob([content], { type: contentType });

    if (window.navigator.msSaveOrOpenBlob) {
        // For IE
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // For onthers browsers
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
};

export const downloadCsv = ({
    filename, content,
}) => {
    return downloadFile({
        filename,
        content: universalBOM + content,
        contentType: 'text/csv',
    });
};

export const downloadPdf = ({
    filename, content,
}) => {
    return downloadFile({
        filename,
        content,
        contentType: 'application/pdf',
    });
};

export const openBase641 = base64 => {
    const linkSource = `data:application/pdf;base64,${base64}`;
    window.open(linkSource, '_blank');
};

export function openBase64(base64URL) {
    const win = window.open();
    const linkSource = `data:application/pdf;base64,${base64URL}`;
    win.document.write(`<iframe src="${linkSource}" style="width:100%; height:100%;" allowfullscreen></iframe>`);
}
