import i18n from '~/translations';

import { INPUT_TYPES } from '.';

const OPERATORS = {
    CONTAIN: 'LIKE',
    BETWEEN: 'BETWEEN',
    EQUAL: '=',
    BIGGER_THAN: '>=',
    SMALLER_THAN: '<=',
    NOT_EQUAL: '!=',
    IN: 'IN',
};

const formatFieldValue = filter => {
    const {
        date,
        range,
        value,
        type,
    } = filter;

    switch (type.item.filterType) {
        case INPUT_TYPES.date.key:
            return range?.length ? range.join('|') : date;
        case INPUT_TYPES.monetary.key:
        case INPUT_TYPES.numeric.key:
            return value;
        case INPUT_TYPES.search.key:
        case INPUT_TYPES.select.key:
            return filter.options.key;
        case INPUT_TYPES.text.key:
            return filter.text;
        default:
            return null;
    }
};

const formatFieldArrayToSend = filters => {
    const formattedFilters = [];

    try {
        filters?.forEach((filter, index) => {
            const variation = filter.variation?.key ?? 'EQUAL';

            formattedFilters.push({
                field: filter.type.key,
                condition: OPERATORS[variation],
                value: formatFieldValue(filter),
            });

            if (index < (filters.length - 1)) {
                formattedFilters.push({ logicalOperator: filter.logicalOperator.key });
            }
        });
    } catch (error) {
        console.warn(error);
    }
    return formattedFilters;
};

const formatFieldArrayConfig = options => {
    return options.map(option => ({
        key: option.key,
        label: i18n.t(`VARIABLE_FILTER_LABELS:${option.label}`),
        item: option,
    }));
};

export {
    formatFieldArrayToSend,
    formatFieldArrayConfig,
};
