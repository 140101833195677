const compareStrings = (value, compareValue) => {
    return value.toLowerCase().includes(compareValue.toLowerCase());
};

export default async (axios, searchValue, page, limit, params = {}) => {
    const { data } = await axios.get(`/account-integrations/channel/${params.marketplaceId}/summary`);
    let result = data.map(item => ({
        label: item.accountName,
        key: item.id,
        item,
    }));

    if (searchValue) {
        result = result.filter(item => compareStrings(item.label, searchValue));
    }

    return {
        result,
        metadata: {
            page: 1,
            pages: 1,
        },
    };
};
