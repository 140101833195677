
export default {
    setItem(key, value) {
        if (window && window.localStorage) {
            localStorage.setItem(key, value);
        }
    },

    getItem(key) {
        if (!window || !window.localStorage) {
            return {};
        }

        return localStorage.getItem(key);
    },

    saveColumnsConfig(pageId, columns) {
        this.setItem(`${pageId}-columns`, JSON.stringify(columns));
    },

    saveFiltersConfig(pageId, filtersConfig) {
        this.setItem(`${pageId}-filtersConfig`, JSON.stringify(filtersConfig));
    },

    loadColumnsConfig(pageId) {
        const columns = this.getItem(`${pageId}-columns`);
        return columns ? JSON.parse(columns) : null;
    },

    clearColumns() {
        if (window && window.localStorage) {
            const keysToRemove = [];
            for (let i = 0; i < localStorage.length; i++) {
                const columnText = localStorage.key(i);
                if (columnText && columnText.endsWith('-columns')) {
                    keysToRemove.push(columnText);
                }
            }
            keysToRemove.forEach(key => localStorage.removeItem(key));
        }
    },

    loadFiltersConfig(pageId) {
        const filtersConfig = this.getItem(`${pageId}-filtersConfig`);
        return filtersConfig ? JSON.parse(filtersConfig) : null;
    },

    saveFilters(pageId, filters) {
        if (window && window.localStorage) {
            localStorage.setItem(`${pageId}-filters`, JSON.stringify(filters));
        }
    },

    clearFilters() {
        if (window && window.localStorage) {
            const keysToRemove = [];
            for (let i = 0; i < localStorage.length; i++) {
                const filterText = localStorage.key(i);
                if (filterText && filterText.endsWith('-filters')) {
                    keysToRemove.push(filterText);
                }
            }
            keysToRemove.forEach(key => localStorage.removeItem(key));
        }
    },

    loadFilters(pageId) {
        if (!window || !window.localStorage) {
            return {};
        }

        try {
            const content = localStorage.getItem(`${pageId}-filters`);
            return content ? JSON.parse(content) : {};
        } catch (err) {
            return {};
        }
    },
};
