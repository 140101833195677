import axios from 'axios';
import JwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

import { createAbility } from '~/helpers/ability-helper';
import UserPreferences from '~/helpers/user-preferences-helper';
import { actions } from '~/redux/ducks/user';

const TOKEN_KEY = 'TOKEN_KEY';
const USER_KEY = 'USER_KEY';
const IS_NEW = 'IS_NEW';

export const setAuthenticationUser = user => {
    return async (dispatch, getState) => {
        await UserPreferences.setItem(USER_KEY, user);
        dispatch(actions.setUser(user));
    };
};

export const setStatusFirstCompanyLogin = status => {
    return async (dispatch, getState) => {
        await UserPreferences.setItem(IS_NEW, status);
        dispatch(actions.setIsNewSender(status));
    };
};

const setAuthenticationToken = ({ token }) => {
    return async (dispatch, getState) => {
        await UserPreferences.setItem(TOKEN_KEY, token);
        dispatch(actions.setToken(token));
    };
};

export const setAuthenticationData = ({
    token,
    name,
    email,
    companyId,
    companyName,
}) => {
    const user = {
        token,
        email,
        name,
        companyId,
        companyName,
    };

    return async (dispatch, getState) => {
        await dispatch(setAuthenticationToken({ token }));
        await dispatch(setAuthenticationUser(user));
    };
};

export const removeAuthenticationData = () => {
    return async (dispatch, getState) => {
        UserPreferences.clear();
        dispatch(actions.logoutUser());
        dispatch(actions.setAbility(createAbility([])));
    };
};

export const decodeToken = token => {
    try {
        return JwtDecode(token);
    } catch (ex) {
        console.warn(ex);
    }
    return null;
};

export const teste = () => {
    return false;
};

export const loadAuthenticationData = () => {
    return async (dispatch, getState) => {
        const token = await UserPreferences.getItem(TOKEN_KEY);

        if (!token) {
            return false;
        }

        const payload = decodeToken(token);
        if (!payload) {
            return false;
        }

        const expirationDate = moment(payload.exp * 1000);
        if (moment().isAfter(expirationDate)) {
            await dispatch(removeAuthenticationData());
            return false;
        }

        const user = await UserPreferences.getItem(USER_KEY);
        dispatch(actions.setToken(token));
        dispatch(actions.setUser(user));
        return true;
    };
};

export const refreshUserAuthentication = () => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const options = await UserPreferences.options(TOKEN_KEY);
            const { data } = await axios.post('/auth/refresh', { refresh_token: state.user.refreshToken });

            return dispatch(setAuthenticationData({
                ...data,
                keepLoggedIn: !options.temporary,
            }));
        } catch (ex) {
            const { response } = ex;
            if (response && response.status === 403) {
                throw ex;
            }
            return dispatch(removeAuthenticationData());
        }
    };
};
