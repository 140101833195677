export default async (axios, searchValue, page, limit, params = {}) => {
    const response = await axios.get('/services/anyenvios/api/shipping-list-statuses');

    const { data } = response;

    return {
        metadata: {
            page: 0,
            pages: 1,
        },
        result: data.map(item => ({
            label: item.name,
            key: item.id,
            item,
        })),
    };
};
