export default async (axios, searchValue, page, limit, params = {}) => {

    const response = await axios.get('/v1/marketplaces');

    const { data } = response;

    return {
        metadata: {
            page: 1,
            pages: 1,
        },
        result: data.map(marketplace => {
            return {
                key: marketplace.id,
                label: marketplace.name,
            };
        }),
    };
};
