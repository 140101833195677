import i18n from '~/translations';

export default async (axios, searchValue, page, limit, params = {}) => {
    const response = await axios.get('/v1/rules/status');

    const { data } = response;

    return {
        metadata: {
            page: 1,
            pages: 1,
        },
        result: Object.keys(data.ruleLogStatus).map(key => {
            return {
                key,
                label: i18n.t(`VARIABLE_FILTER_LABELS:${data.ruleLogStatus[key].toUpperCase()}`),
            };
        }),
    };
};
