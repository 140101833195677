const compareStrings = (value, compareValue) => {
    return value.toLowerCase().includes(compareValue.toLowerCase());
};

export default async (axios, searchValue, page, limit, params = {}) => {
    const { data } = await axios.get('/channels', {
        params: {
            ...params,
        },
    });
    let result = data.map(item => ({
        label: item.name,
        key: item.id,
        item,
    }));

    if (params.name) {
        result = result.filter(item => compareStrings(item.label, params.name));
    }

    if (searchValue) {
        result = result.filter(item => compareStrings(item.label, searchValue));
    }

    return {
        result,
        metadata: {
            page: 1,
            pages: 1,
        },
    };
};
