import React from 'react';

import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from './Button';
import styles from './FilterContainer.module.scss';

const { Group: ButtonGroup } = Button;

const FilterContainer = ({ children, loading, onClearClick }) => {
    const { t } = useTranslation('FILTER_CONTAINER');

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {children}
            </div>

            <ButtonGroup className={styles.buttonGroup}>
                <Button
                    htmlType="submit"
                    icon={<SearchOutlined />}
                    loading={loading}
                    title={t('SEARCH')}
                    borderRadius={null}
                />
                {onClearClick ? (
                    <AntButton
                        htmlType="button"
                        icon={<CloseOutlined />}
                        disabled={loading}
                        onClick={onClearClick}
                        title={t('CLEAR_FILTERS')}
                        danger
                        size="large"
                    />
                ) : null}
            </ButtonGroup>
        </div>
    );
};

export default FilterContainer;
