/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import { BellOutlined } from '@ant-design/icons';
import { notification, Button, Popover } from 'antd';

import NotificationCard from './NotificationCard';
import styles from './styles.module.scss';

notification.config({
    placement: 'topRight',
});

function NotificationWidget({ companyId }) {
    const [notitifications, setNotifications] = useState({});
    const [isAnimationRunning, setIsAnimationRunning] = useState(false);

    const hasNotifications = messages => {
        const isNotification = false;
        setIsAnimationRunning(isNotification);
    };

    const showNotification = message => {
        if (message.level === 'error') {
            notification.error({
                message: message.title,
                description: message.message,
            });
        } else if (message.level === 'info') {
            notification.info({
                message: message.title,
                description: message.message,
            });
        } else if (message.level === 'warn') {
            notification.warn({
                message: message.title,
                description: message.message,
            });
        }

    };

    const NOTIFICATIONHUB_URL = process.env.REACT_APP_NOTIFICATIONHUB;
    const SERVER_WEBHOOK_URL = `${NOTIFICATIONHUB_URL}/ws?companyId=${companyId}`;

    useEffect(() => {
        const socket = new WebSocket(SERVER_WEBHOOK_URL);

        socket.onopen = () => {
            console.log('Conectado ao servidor WebSocket');
        };

        socket.onmessage = event => {
            const jsonMessage = event.data;
            const message = JSON.parse(jsonMessage);

            setNotifications(message);
            if (message) {
                console.log('Objeto da mensagem: ', message);

                hasNotifications(message);
                showNotification(message);
            }
        };

        socket.onclose = event => {
            if (event.wasClean) {
                console.log(`Conexão fechada limpa, código=${event.code}, motivo=${event.reason}`);
            } else {
                console.error('Conexão interrompida');
            }
        };

        socket.onerror = error => {
            console.error(`Erro de WebSocket: ${error}`);
        };

        return () => {
            socket.close();
        };
    }, [SERVER_WEBHOOK_URL]);

    const animationState = isAnimationRunning ? 'running' : 'paused';

    const containerStyle = {
        '--animation-state': animationState,
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.bellIcon} style={containerStyle}>
                    <div className={styles.bell}>
                        <div className={styles.bellBorder} />
                        <Popover
                            placement="bottomLeft"
                            title=""
                            content={<NotificationCard data={notitifications.data} />}
                            trigger="click"
                        >
                            <Button
                                className={styles.btnBell}
                                type="text"
                                onClick={() => { }}
                                icon={<BellOutlined />}
                            />
                        </Popover>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotificationWidget;
