import React, { useCallback, useState } from 'react';

import { Image, message } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import BuyboxTagDescription from '~/components/BuyboxTagDescription';
import { maskMoney } from '~/helpers/mask-helper';
import useAxios from '~/hooks/use-axios';
import useDidMount from '~/hooks/use-did-mount';
import { BUYBOX_STATUS, MARKETPLACES_BY_ID } from '~/values/enums';

import styles from './AdModalHeader.module.scss';

const MODAL_OPTIONS = {
    PricingDialog: 'pricing',
    CompetitorMonitoring: 'monitoring',
    CompetitorComparison: 'comparison',
};

const InfoLine = ({ title, description, className }) => {
    return (
        <div className={className}>
            <div className={styles.infoLineTitle}>{title}</div>
            {description}
        </div>
    );
};

function AdModalHeader({ listingId, modal }) {
    const axios = useAxios({
        baseURL: process.env.REACT_APP_URL_API_GO,
    });
    const { t } = useTranslation('AD_MODAL_HEADER');
    const [data, setData] = useState({});

    useDidMount(() => {
        const handlerData = async () => {
            try {
                const response = await axios.get(`/v1/listings/${listingId}`);
                setData(response.data);
            } catch (error) {
                message.error('Erro ao buscar a listagem:', error);
            }
        };

        handlerData();
    }, []);

    const {
        imageUri,
        title,
        sku,
        skuMarketplace,
        price,
        shippingPrice,
        stock,
        marketplaceId,
        buyboxStatus,
        position,
    } = data;

    const marketplaceName = MARKETPLACES_BY_ID.getItemByKey(marketplaceId)?.label || null;

    const renderBuyboxTag = useCallback(() => {
        return <BuyboxTagDescription data={data} />;
    }, [data]);

    const renderRankingHeader = useCallback(rank => {
        if (!position || (rank == null && buyboxStatus === BUYBOX_STATUS.BUYBOX_LOSING)) return '-';

        if (rank == null && (buyboxStatus === BUYBOX_STATUS.BUYBOX_WINNING)) return '1°';

        return `${rank}°`;
    }, [buyboxStatus, position]);

    return (
        <div className={classNames([styles.headerContainer, styles[modal]])}>
            <div className={styles.imageContainer}>
                <Image
                    src={imageUri}
                    width="100%"
                    preview={false}
                    fallback={require('~/assets/no-image.png').default}
                />
            </div>
            <InfoLine className={styles.adTitle} title={t('AD')} description={title} />

            {buyboxStatus && modal === MODAL_OPTIONS.CompetitorComparison ? (
                <InfoLine title={t('BUYBOX')} description={renderBuyboxTag(buyboxStatus)} />
            ) : null}

            <InfoLine title={t('MARKETPLACE')} description={marketplaceName} />
            <InfoLine title={t('SKU')} description={sku} />
            <InfoLine title={t('SKU_MARKETPLACE')} description={skuMarketplace} />

            {position && modal === MODAL_OPTIONS.CompetitorComparison ? (
                <InfoLine title={t('YOUR_POSITION')} description={renderRankingHeader(position)} />
            ) : null}

            {stock && modal === MODAL_OPTIONS.CompetitorMonitoring ? (
                <InfoLine title={t('STOCK')} description={stock} />
            ) : null}

            {price && (modal === MODAL_OPTIONS.PricingDialog || modal === MODAL_OPTIONS.CompetitorMonitoring) ? (
                <InfoLine
                    title={t('PRICE')}
                    description={maskMoney(price, { currencyCode: 'BRL', countryCode: 'BR' })}
                />
            ) : null}

            {shippingPrice && modal === MODAL_OPTIONS.PricingDialog ? (
                <InfoLine
                    title={t('SHIPPING_PRICE')}
                    description={maskMoney(shippingPrice, { currencyCode: 'BRL', countryCode: 'BR' })}
                />
            ) : null}
        </div>
    );
}

export default AdModalHeader;
