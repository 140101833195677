import i18n from '~/translations';

const compareStrings = (value, compareValue) => {
    return value.toLowerCase().includes(compareValue.toLowerCase());
};

export default async (axios, searchValue, page, limit, params = {}) => {
    const { data } = await axios.get('/campaign-strategies');
    let result = data.map(item => ({
        label: i18n.t('INPUT_SEARCH:REQUEST_STRATEGIES_LABEL', {
            name: item.name,
            initialValue: item.initialValue,
            finalValue: item.finalValue,
        }),
        key: item.id,
        item,
    }));

    if (searchValue) {
        result = result.filter(item => compareStrings(item.label, searchValue));
    }

    return {
        result,
        metadata: {
            page: 1,
            pages: 1,
        },
    };
};
