import React from 'react';

import { useTranslation } from 'react-i18next';

import { validateNotEmpty } from '~/helpers/validations';
import i18n from '~/translations';

import Input from '../Input';

export const DATE_VARIATIONS = {
    BETWEEN: i18n.t('DATE_INPUT_VARIATIONS:BETWEEN'),
    EQUAL: i18n.t('DATE_INPUT_VARIATIONS:EQUAL'),
    BIGGER_THAN: i18n.t('DATE_INPUT_VARIATIONS:BIGGER_THAN'),
    SMALLER_THAN: i18n.t('DATE_INPUT_VARIATIONS:SMALLER_THAN'),
};

const DateInputVariations = ({ fieldName, variation }) => {
    const { t } = useTranslation('DATE_INPUT_VARIATIONS');

    if (variation === 'BETWEEN') {
        return (
            <Input.Field
                required
                type="daterange"
                name={`${fieldName}.range`}
                validate={validateNotEmpty}
                dateFormat={t('GLOBAL:SIMPLE_DATE_FORMAT')}
                size="default"
            />
        );
    }

    return (
        <Input.Field
            required
            type="date"
            name={`${fieldName}.date`}
            validate={validateNotEmpty}
            dateFormat={t('GLOBAL:SIMPLE_DATE_FORMAT')}
            size="default"
        />
    );
};

export default DateInputVariations;
