import React, { memo } from 'react';

import { Pagination as AntPagination } from 'antd';
import PropTypes from 'prop-types';

import i18n from '~/translations';

import styles from './Pagination.module.scss';

export function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <a className={styles.pagePrevNextButton}>{i18n.t('PAGINATION:PREVIOUS')}</a>;
    }
    if (type === 'next') {
        return <a className={styles.pagePrevNextButton}>{i18n.t('PAGINATION:NEXT')}</a>;
    }
    return originalElement;
}

const Pagination = memo(({
    ...others
}) => {
    return (
        <AntPagination
            size="small"
            pageSize={15}
            showSizeChanger={false}
            {...others}
            itemRender={itemRender}
        />
    );
});

Pagination.propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    loading: PropTypes.bool,
};

export default Pagination;
