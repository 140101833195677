/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */

import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
    Divider,
    Form,
    List,
    message,
    Modal,
    Radio,
    Space,
    Switch,
    Tag,
    InputNumber,
    Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AdDescriptionColumn from '~/components/AdDescriptionColumn/AdDescriptionColumn';
import AdModalHeader from '~/components/AdModalHeader/AdModalHeader';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import Table from '~/components/Table';
import { wrapDialog } from '~/helpers/dialog-helper';
import { wrapForm } from '~/helpers/form-helper';
import { maskMoney } from '~/helpers/mask-helper';
import { parseDecimal } from '~/helpers/parser-helper';
import useAxios from '~/hooks/use-axios';
import useDidMount from '~/hooks/use-did-mount';

import styles from './PricingDialog.module.scss';

const MODAL_STYLE = { top: 60 };
const { Column } = Table;

const PricingDialog = ({
    data, onCancel, onCancelPricingDialog,
    ...others
}) => {
    const axios = useAxios({
        baseURL: process.env.REACT_APP_URL_API_GO,
    });
    const { t } = useTranslation('PRICING_DIALOG');
    const adInfo = data[0];

    const [radioValueObjective, setRadioValueObjective] = useState(0);
    const [radioValueOverride, setRadioValueOverride] = useState(0);
    const [radioValuePermission, setRadioValuePermission] = useState(0);
    const [initialValues, setInitialValues] = useState({});
    const [massAction, setMassAction] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [valid, setValid] = useState(data.map(ad => {
        if (data.length === 1 && adInfo.ruleStatus !== 'none') {
            return {
                listingId: ad.id,
                valid: true,
            };
        }
        return {
            listingId: ad.id,
            valid: false,
        };
    }));
    const minMaxValues = useRef(data.map(ad => {
        const obj = {
            listingId: ad.id,
            sku: ad.sku,
            ruleStrategy: 'buybox_to_win',
            overrideAuthority: true,
            requiresConfirmation: true,
            minPrice: 0,
            maxPrice: 0,
        };

        if (data.length === 1 && adInfo.ruleStatus !== 'none') {
            obj.ruleStatus = adInfo.ruleStatus;
        }

        return obj;
    }, []));
    const ruleId = useRef(0);

    const RULE_LIST = {
        ruleStrategy: {
            title: t('OBJECTIVE_TITLE'),
            items: [
                {
                    title: t('WIN_OBJECTIVE_TITLE'),
                    description: t('WIN_OBJECTIVE_DESCRIPTION'),
                    disabled: false,
                },
                {
                    title: t('WIN_EXCEPTION_OBJECTIVE_TITLE'),
                    description: t('WIN_EXCEPTION_OBJECTIVE_DESCRIPTION'),
                    disabled: true,
                },
            ],
        },
        overrideAuthority: {
            title: t('OVERRIDE_TITLE'),
            subtitle: t('OVERRIDE_SUBTITLE'),
            items: [
                {
                    title: t('REGAIN_OVERRIDE_TITLE'),
                    description: t('REGAIN_OVERRIDE_DESCRIPTION'),
                    disabled: false,
                },
                {
                    title: t('LOSE_OVERRIDE_TITLE'),
                    description: t('LOSE_OVERRIDE_DESCRIPTION'),
                    disabled: false,
                },
            ],
        },
        requiresConfirmation: {
            title: t('PERMISSION_TITLE'),
            items: [
                {
                    title: t('ASK_PERMISSION_TITLE'),
                    description: t('ASK_PERMISSION_DESCRIPTION'),
                    disabled: false,
                },
                {
                    title: t('DONT_ASK_PERMISSION_TITLE'),
                    description: t('DONT_ASK_PERMISSION_DESCRIPTION'),
                    disabled: false,
                },
            ],
        },
    };

    const requestDataSource = useCallback(async () => {
        const listingId = adInfo.listingId || adInfo.id;
        try {
            const response = await axios.get(`v1/rules/${listingId}`);
            return response;
        } catch (e) {
            message.error('Connection Error: ', e);
            return {};
        }
    }, [axios]);

    useDidMount(() => {
        if (data.length > 1) {
            setMassAction(true);
        }
    }, []);

    useEffect(async () => {
        if (data.length === 1 && adInfo.ruleStatus !== 'none') {
            const response = await requestDataSource();
            setInitialValues(response.data);
            minMaxValues.current[0].minPrice = response.data?.minPrice;
            minMaxValues.current[0].maxPrice = response.data?.maxPrice;
            setRadioValueObjective(response.data?.ruleStrategy === 'buybox_to_win' ? 0 : 1);
            setRadioValueOverride(response.data?.overrideAuthority ? 0 : 1);
            setRadioValuePermission(response.data?.requiresConfirmation ? 0 : 1);
            ruleId.current = response.data?.id;
        }
        setIsLoading(false);
    }, []);

    const renderMoneyColumn = useCallback((value, adData) => {
        const money = maskMoney(value, adData);
        return money;
    }, []);

    const onSwitchChange = value => {
        if (value) {
            minMaxValues.current[0].ruleStatus = 'enabled';
            return;
        }
        minMaxValues.current[0].ruleStatus = 'disabled';
    };

    const renderStatus = useCallback(status => {
        if (!massAction) {
            const newValue = minMaxValues.current[0];
            newValue.ruleStatus = status;
            minMaxValues.current = [newValue];
        }
        return (
            <div className={styles.ruleStatusContainer}>
                <Switch
                    checkedChildren={t('STATUS_ENABLED')}
                    unCheckedChildren={status === 'paused' ? t('STATUS_PAUSED') : t('STATUS_DISABLED')}
                    onChange={value => onSwitchChange(value)}
                    size="default"
                    defaultChecked={status === 'enabled'}
                />
            </div>
        );
    }, []);

    const renderHeader = useMemo(() => {
        return (
            <div className={styles.pricingHeaderContainer}>
                <AdModalHeader listingId={adInfo.listingId || adInfo.id} modal="pricing" />
                {adInfo.ruleStatus === 'none' ? null : (
                    <div className={styles.switchContainer}>
                        <div className={styles.infoLineTitle}>{t('RULE_STATUS')}</div>
                        {renderStatus(adInfo.ruleStatus)}
                    </div>
                )}
            </div>
        );
    }, [data, initialValues]);

    const onRadioChange = (e, radioGroup) => {
        let newValues = [];
        switch (radioGroup) {
            case 0:
                setRadioValueObjective(e.target.value);
                newValues = minMaxValues.current.map(ad => {
                    if (e.target.value === 0) {
                        ad.ruleStrategy = 'buybox_to_win';
                    }
                    ad.ruleStrategy = 'buybox_to_win_exceptions';
                    return ad;
                });
                break;
            case 1:
                setRadioValueOverride(e.target.value);
                newValues = minMaxValues.current.map(ad => {
                    if (e.target.value === 0) {
                        ad.overrideAuthority = true;
                        return ad;
                    }
                    ad.overrideAuthority = false;
                    return ad;
                });
                break;
            case 2:
                setRadioValuePermission(e.target.value);
                newValues = minMaxValues.current.map(ad => {
                    if (e.target.value === 0) {
                        ad.requiresConfirmation = true;
                        return ad;
                    }
                    ad.requiresConfirmation = false;
                    return ad;
                });
                break;
            default:
                break;
        }
        minMaxValues.current = newValues;
    };

    const ExtraInfoTooltip = ({
        title1, info1, title2, info2, extra, pricing,
    }) => {
        return (
            <Tooltip
                title={(
                    <span className={styles.tooltipInformationCard}>
                        {pricing || (
                            <>
                                {extra ? <>{extra}<br /></> : null}
                                <span><b>{title1}:</b> {info1}</span>
                                <br />
                                <span><b>{title2}:</b> {info2}</span>
                            </>
                        )}
                    </span>
                )}
                color="white"
                overlayClassName={styles.tooltipInformationCard}
            >
                <InfoCircleOutlined />
            </Tooltip>
        );
    };

    const renderRules = useMemo(() => {
        return (
            <>
                {Object.keys(RULE_LIST).map(rule => {
                    const currentRule = RULE_LIST[rule];
                    const itemsList = currentRule.items;
                    let radioGroup = 0;
                    let radioValue = 0;

                    switch (rule) {
                        case 'ruleStrategy':
                            radioGroup = 0;
                            radioValue = radioValueObjective;
                            break;
                        case 'overrideAuthority':
                            radioGroup = 1;
                            radioValue = radioValueOverride;
                            break;
                        case 'requiresConfirmation':
                            radioGroup = 2;
                            radioValue = radioValuePermission;
                            break;
                        default:
                            return null;
                    }

                    return (
                        <div key={currentRule.title}>
                            <div className={styles.listTitleContainer}>
                                <Title level={4} type="secondary" className={styles.listTitle}>
                                    {currentRule.title}
                                </Title>
                                <ExtraInfoTooltip
                                    title1={itemsList[0].title}
                                    info1={itemsList[0].description}
                                    title2={itemsList[1].title}
                                    info2={itemsList[1].description}
                                    extra={currentRule.subtitle || null}
                                />
                            </div>
                            <Radio.Group
                                className={styles.group}
                                onChange={e => onRadioChange(e, radioGroup)}
                                value={radioValue}
                            >
                                {currentRule.items.map((item, index) => {
                                    return (
                                        <List.Item key={item.title}>
                                            <List.Item.Meta
                                                avatar={(
                                                    <Radio value={index} disabled={item.disabled} />
                                                )}
                                                title={item.disabled
                                                    ? (
                                                        <div className={styles.comingSoonTitle}>
                                                            {item.title}
                                                            &nbsp;
                                                            <Tag
                                                                className={classNames([styles.tag, styles.comingSoon])}
                                                            >
                                                                {t('COMING_SOON')}
                                                            </Tag>
                                                        </div>
                                                    ) : (<span className={styles.optionTitle}>{item.title}</span>)}
                                            />
                                        </List.Item>
                                    );
                                })}
                            </Radio.Group>
                        </div>
                    );
                }, [radioValueObjective, radioValueOverride, initialValues, radioValuePermission])}
            </>
        );
    }, [radioValueObjective, radioValueOverride, initialValues, radioValuePermission]);

    const onValuesChange = useCallback((value, adData, form) => {
        let newValidInputs = [...valid];

        const newValues = minMaxValues.current.map(ad => {
            if (ad.listingId === adData.id) {
                ad.minPrice = form.getFieldValue(`minPrice:${adData.id}`);
                ad.maxPrice = form.getFieldValue(`maxPrice:${adData.id}`);
            }
            return ad;
        });
        minMaxValues.current = newValues;

        newValidInputs = valid.map(ad => {
            if (ad.listingId === adData.id) {
                ad.valid = false;
            }
            return ad;
        });

        form.validateFields([`maxPrice:${adData.id}`]).then(result => {
            if (result) {
                newValidInputs = valid.map(ad => {
                    if (ad.listingId === adData.id) {
                        if (form.getFieldValue(`minPrice:${adData.id}`) !== 0) {
                            ad.valid = true;
                        }
                    }
                    return ad;
                });
            }
        }).catch(err => null);

        setValid(newValidInputs);
        return null;
    }, [minMaxValues]);

    const RenderPriceRangeInputFields = ({ adData, massRender }) => {
        const [form] = Form.useForm();
        const [helpMessage, setHelpMessage] = useState({ minPrice: '', maxPrice: '' });

        const checkMinMaxPricing = () => {
            const actualMinPrice = form.getFieldValue(`minPrice:${adData.id}`);
            const actualMaxPrice = form.getFieldValue(`maxPrice:${adData.id}`);

            const calculateDiffPercentage = price => ((Math.abs(price - adData.price) / adData.price) * 100);

            const getMessage = (price, diffPercentage) => t('VALIDATIONS:VALIDATE_MIN_MAX_%')
                .replace('XX', diffPercentage.toFixed(0))
                .replace('YY', price > adData.price ? '+' : '-');

            const minPriceDiffPercentage = calculateDiffPercentage(actualMinPrice);
            const maxPriceDiffPercentage = calculateDiffPercentage(actualMaxPrice);

            const newHelpMessage = {
                minPrice: minPriceDiffPercentage > 1 ? getMessage(actualMinPrice, minPriceDiffPercentage) : '',
                maxPrice: (actualMaxPrice <= actualMinPrice) ? null
                    : (maxPriceDiffPercentage > 1 ? getMessage(actualMaxPrice, maxPriceDiffPercentage) : ''),
            };

            setHelpMessage(newHelpMessage);
        };

        let minPrice = 0;
        let maxPrice = 0;

        if (initialValues && Object.keys(initialValues).length > 0) {
            minPrice = initialValues.minPrice;
            maxPrice = initialValues.maxPrice;
        }

        return (
            <Form
                form={form}
                name={adData.id}
                preserve={false}
                validateTrigger="onBlur"
                onValuesChange={values => {
                    onValuesChange(values, adData, form);
                    checkMinMaxPricing();
                }}
                className={massRender
                    ? classNames([styles.inputFieldsCenter, styles.inputFieldsMass])
                    : classNames([styles.inputFieldsCenter, styles.inputFieldsSingle])}
                initialValues={{ [`minPrice:${adData.id}`]: minPrice, [`maxPrice:${adData.id}`]: maxPrice }}
            >
                <Form.Item
                    name={`minPrice:${adData.id}`}
                    label={t('MINIMUM_PRICE_COLUMN')}
                    className={styles.inputWithNoMargin}
                    required
                    rules={[
                        { required: true, message: t('VALIDATIONS:VALIDATE_REQUIRED') },
                    ]}
                    help={helpMessage.minPrice}
                >
                    <InputNumber
                        className={styles.inputField}
                        size={massRender ? 'middle' : 'large'}
                        type="text"
                        parser={parseDecimal}
                        formatter={value => renderMoneyColumn(parseFloat(value), adData)}
                    />
                </Form.Item>
                <Form.Item
                    name={`maxPrice:${adData.id}`}
                    label={t('MAXIMUM_PRICE_COLUMN')}
                    className={styles.inputWithNoMargin}
                    required
                    rules={[
                        { required: true, message: t('VALIDATIONS:VALIDATE_REQUIRED') },
                        forms => ({
                            type: 'number',
                            min: forms.getFieldValue(`minPrice:${adData.id}`) + 0.001,
                            message: t('VALIDATIONS:VALIDATE_LESSER_THAN'),
                        }),
                    ]}
                    help={helpMessage.maxPrice}
                >
                    <InputNumber
                        className={styles.inputField}
                        size={massRender ? 'middle' : 'large'}
                        type="text"
                        parser={parseDecimal}
                        formatter={value => renderMoneyColumn(parseFloat(value), adData)}
                    />
                </Form.Item>
            </Form>
        );
    };

    const renderListing = useMemo(() => {
        return (
            <Table
                {...others}
                dataSource={data}
                scroll={{ y: 450 }}
                pagination={false}
            >
                <Column
                    title={t('AD_COLUMN')}
                    key="title"
                    dataIndex="title"
                    render={(_, adData) => <AdDescriptionColumn adData={adData} />}
                    align="center"
                    width={400}
                />
                <Column
                    title={t('PRICE_COLUMN')}
                    key="price"
                    dataIndex="price"
                    dataSorter="price"
                    render={renderMoneyColumn}
                    align="center"
                    width={90}
                />
                <Column
                    title={t('SHIPPING_COLUMN')}
                    key="shippingPrice"
                    dataIndex="shippingPrice"
                    dataSorter="shippingPrice"
                    render={renderMoneyColumn}
                    align="center"
                    width={90}
                />
                <Column
                    title={t('PRICE_RANGE_COLUMN')}
                    render={(_, adData) => <RenderPriceRangeInputFields adData={adData} massRender={massAction} />}
                    align="center"
                    width={260}
                />
            </Table>
        );
    }, [t, data, initialValues, massAction]);

    const renderPricingSection = useMemo(() => {
        return (
            <div>
                <div className={classNames([styles.listTitleContainer, styles.pricingLimits])}>
                    <Title level={4} type="secondary" className={styles.listTitle}>
                        {t('PRICING_SECTION_TITLE')}
                    </Title>
                    <ExtraInfoTooltip pricing={t('PRICING_SECTION_SUBTITLE')} />
                </div>
                <RenderPriceRangeInputFields adData={adInfo} massRender={massAction} />
            </div>
        );
    }, [adInfo, t, massAction]);

    const renderMassPricingSection = useMemo(() => {
        return (
            <div>
                <div className={styles.center}>
                    <Divider>
                        <div className={styles.listTitleContainer}>
                            <Title level={3} type="secondary" className={styles.listTitle}>
                                {t('PRICING_SECTION_TITLE')}
                            </Title>
                            <ExtraInfoTooltip pricing={t('PRICING_SECTION_SUBTITLE')} />
                        </div>
                    </Divider>
                </div>
                {renderListing}
            </div>
        );
    }, [renderListing, massAction, t]);

    const renderRulesSection = useMemo(() => {
        return (
            <div>
                <Space
                    direction="vertical"
                    className={styles.space}
                    size={10}
                >
                    <Divider>
                        <Title level={3} type="secondary" className={styles.listTitle}>
                            {t('RULES_SECTION_TITLE')}
                        </Title>
                    </Divider>

                    <div className={styles.configurationsContainer}>
                        {renderRules}
                        {massAction ? null : renderPricingSection}
                    </div>
                </Space>
            </div>
        );
    }, [renderRules, initialValues, massAction]);

    const cancel = () => {
        onCancelPricingDialog();
        onCancel();
    };

    const onConfirmClick = useCallback(async () => {
        const validConfirm = valid.filter(ad => !ad.valid).length === 0;

        if (validConfirm) {
            try {
                if (!massAction && adInfo.ruleStatus !== 'none') {

                    await axios.put(`v1/rules/${ruleId.current}`, minMaxValues.current[0]);
                    if (minMaxValues.current[0].ruleStatus !== initialValues?.ruleStatus) {
                        await axios.put(`v1/rules/${ruleId.current}/status`, {
                            listingId: adInfo.id,
                            ruleStatus: minMaxValues.current[0].ruleStatus,
                        });
                    }
                } else {
                    await axios.post('v1/rules', {
                        data: minMaxValues.current,
                    });
                }
                message.success(t('SUCCESS_ON_CREATE_RULE'));
            } catch (err) {
                message.error(t('ERROR_ON_CREATE_RULE'));
            }
            cancel();
            return;
        }

        message.error(t('CORRECT_INVALID_ADS'));
    }, [initialValues, minMaxValues, t]);

    const renderSubmitButtons = useMemo(() => {
        return (
            <div className={styles.buttonContainer}>
                <Button className={styles.submitButtons} onClick={onConfirmClick}>
                    {t('CONFIRM')}
                </Button>
                <Button type="default" className={[styles.submitButtons, styles.cancelButton]} onClick={cancel}>
                    {t('CANCEL')}
                </Button>
            </div>
        );
    }, []);

    return (
        <Modal
            {...others}
            title={massAction ? null : t('PRICING_CONFIGURATION_TITLE')}
            closable
            destroyOnClose
            // width={massAction ? 1040 : 940}
            width={940}
            style={MODAL_STYLE}
            onCancel={cancel}
            footer={renderSubmitButtons}
        >
            <Spinner
                spinning={isLoading}
            >
                <Space
                    direction="vertical"
                    className={styles.space}
                    size={25}
                >
                    {massAction ? null : renderHeader}
                    {renderRulesSection}
                    {massAction ? renderMassPricingSection : null}
                </Space>
            </Spinner>
        </Modal>
    );
};

export default wrapDialog(wrapForm(PricingDialog));
