export default async (axios, searchValue, page, limit, params = {}) => {
    const response = await axios.get('/services/anyenvios/api/cities', {
        params: {
            page,
            name: searchValue,
        },
    });

    const { data } = response;
    const { totalPages, number, content } = data;

    return {
        metadata: {
            page: number,
            pages: totalPages,
        },
        result: content.map(item => ({
            label: `${item.name} - ${item.uf}`,
            key: item.id,
            item,
        })),
    };
};
