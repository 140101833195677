import { useEffect, useMemo, useState } from 'react';

import { message } from 'antd';
import Axios from 'axios';

import { tokenInterceptor } from '~/helpers/axios-interceptor';

const { CancelToken } = Axios;

const useAxios = axiosOptions => {

    // transforma as options em um state para evitar mutabilidade
    const [options] = useState(axiosOptions);

    const source = useMemo(() => CancelToken.source(), []);

    const axios = useMemo(() => {
        const controller = () => new AbortController();

        const instance = Axios.create({
            ...options,
            signal: controller.signal,
            cancelToken: source.token,
        });

        instance.interceptors.request.use(tokenInterceptor);

        if (process.env.REACT_APP_READ_ONLY === 'false') {
            return instance;
        }

        instance.interceptors.request.use(config => {
            // intercept exceptions

            if (options?.exception !== undefined && options?.exception) {
                return config;
            }
            if (config.url.includes('aauth')) {
                return config;
            }
            if (config.url.includes('query')) {
                return config;
            }
            if (config.method === 'get') {
                return config;
            }

            message.error('Aborted: Read-only mode active');
            controller.abort();
            return config;
        });

        return instance;
    }, [source, options]);

    useEffect(() => {
        return () => {
            source.cancel('Operation canceled by unmounted component.');
        };
    }, [source]);

    return axios;
};

export default useAxios;
