import pipe from 'lodash/fp/pipe';
import VMasker from 'vanilla-masker';

export const removeMask = text => {
    if (typeof text === 'string') {
        return text.replace(/[^\da-z]/gi, '');
    }
    return text;
};

export const removeMoneyMask = (text, allowNegativeNumber = false) => {
    if (typeof text === 'string') {
        if (allowNegativeNumber) {
            return text.replace(/[^\da-z-]/gi, '');
        }
        return text.replace(/[^\da-z]/gi, '');
    }
    return text;
};

export const maskCep = text => (
    typeof text === 'string' ? VMasker.toPattern(text, '99999-999') : text
);

export const maskCPF = text => (
    typeof text === 'string' ? VMasker.toPattern(text, '999.999.999-99') : ''
);

export const maskDate = text => (
    typeof text === 'string' ? VMasker.toPattern(text, '99/99/9999') : ''
);

export const maskCreditCardDueDate = text => {
    return (
        typeof text === 'string' ? VMasker.toPattern(text, '99/9999') : ''
    );
};

export const maskCNPJ = text => (
    typeof text === 'string' ? VMasker.toPattern(text, '99.999.999/9999-99') : ''
);

export const maskTime = text => (
    typeof text === 'string' ? VMasker.toPattern(text, '99:99') : ''
);

export const maskTelefone = text => (
    typeof text === 'string'
        ? text.replace(/[^\d]/g, '').length > 10
            ? VMasker.toPattern(text, '(99) 9 9999-9999')
            : VMasker.toPattern(text, '(99) 9999-9999')
        : ''
);

export const maskInteger = text => {
    if (typeof text === 'string') {
        return text.replace(/[^\d]/gim, '');
    }
    return text;
};

export const maskCreditCardNumber = text => (
    typeof text === 'string' ? (text.length < 18
        ? VMasker.toPattern(text, '9999 999999 99999')
        : VMasker.toPattern(text, '9999 9999 9999 9999')) : ''
);

export const slugifyToURL = text => {
    return text
        .normalize('NFKD')// O normalize() usando NFKD retorna a forma normalizada Unicode da String.
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')// Troca espacos por -
        .replace(/[^\w-]+/g, '')// Retira todos caracteres diferentes de texto
        .replace(/_/g, '-')// Troca _ por -
        .replace(/--+/g, '-')// troca varios - por apenas um -
        .replace(/-$/g, '');// Remove trailing -
};

const renderPrefix = currencyCode => {
    if (currencyCode === null) return '';
    switch (currencyCode) {
        case 'BRL':
            return 'R$ ';
        case 'USD':
            return '$ ';
        case 'MXN':
            return '$ ';
        case 'EUR':
            return '€ ';
        case 'ARS':
            return '$ ';
        case 'CLP':
            return '$ ';
        case 'COP':
            return '$ ';
        case 'PEN':
            return 'S/ ';
        case 'UYU':
            return '$U ';
        case 'PYG':
            return '₲ ';
        case 'BOB':
            return 'Bs. ';
        case 'VES':
            return 'Bs. ';
        case 'CRC':
            return '₡ ';
        case 'DOP':
            return 'RD$ ';
        case 'GTQ':
            return 'Q ';
        case 'HNL':
            return 'L ';
        case 'NIO':
            return 'C$ ';
        case 'PAB':
            return 'B/. ';
        case 'BZD':
            return 'BZ$ ';
        default:
            return '';
    }
};

export const createDecimalFormatter = ({
    precision = 2,
    separator = ',',
    delimiter = '.',
    delimiterCase = 3,
    suffix = '',
    keepLeadingZeros = false,
    allowNegativeNumber = false,
    currencyCode = null,
    v = null,
}) => {
    const removeLeadingZeros = value => (keepLeadingZeros ? value : (value || '').replace(/^0+/, ''));

    const addLeadingZeros = value => (value || '').padStart(precision + 1, '0');

    const precisionRegex = new RegExp(`(\\d*?)(\\d{${precision}})$`);
    const separatorPattern = `$1${separator}$2`;
    const delimiterRegex = new RegExp(`(\\d+)(\\d{3}[${delimiter}${separator}\\d]*)$`);
    const delimiterPattern = `$1${delimiter}$2`;

    const applyMask = (value = '') => {
        const counter = (value.length - (precision + delimiterCase)) / delimiterCase;
        let masked = precision
            ? value.replace(precisionRegex, separatorPattern)
            : value;
        for (let i = 0; i < counter; i += 1) {
            masked = masked.replace(delimiterRegex, delimiterPattern);
        }
        return masked;
    };

    const sanitizeValue = value => {
        switch (typeof value) {
            case 'string':
                return removeMoneyMask(value, allowNegativeNumber);
            case 'number':
                return removeMoneyMask(value.toFixed(precision), allowNegativeNumber);
            default:
                return '';
        }
    };

    const addPrefixSuffix = value => `${renderPrefix(currencyCode)}${value}${suffix}`;

    const formatMoney = pipe(
        sanitizeValue,
        removeLeadingZeros,
        addLeadingZeros,
        applyMask,
        addPrefixSuffix,
    );

    return formatMoney(v);
};

export const maskDecimal = value => {
    return createDecimalFormatter({
        precision: 2,
        separator: ',',
        v: value,
    });
};

export const maskDecimalPrecisionThree = value => {
    return createDecimalFormatter({
        precision: 3,
        separator: ',',
        v: value,
    });
};

export const maskProjectExternalCode = text => (
    typeof text === 'string'
        ? text.replace(/[^A-Za-z]/g, '').toUpperCase()
        : ''
);

const maskNumberWithDigit = value => {
    return createDecimalFormatter({
        precision: 1,
        separator: '-',
        keepLeadingZeros: true,
        v: value,
    });
};

export const maskWithVerificationDigit = value => {
    const stripped = removeMask(value);
    return stripped && stripped.length >= 2
        ? maskNumberWithDigit(stripped)
        : stripped;
};

export const maskMoney = (value, data) => {
    let currencyCode = null;
    if (data?.currencyCode !== null && data?.currencyCode !== undefined) {
        currencyCode = data.currencyCode;
    }
    return createDecimalFormatter({
        currencyCode,
        v: value,
    });
};

export const maskCpfCnpj = text => (
    typeof text === 'string'
        ? removeMask(text).length > 11
            ? maskCNPJ(text)
            : maskCPF(text)
        : text
);

export const maskNumberWithoutDecimal = value => {
    return createDecimalFormatter({
        precision: 0,
        separator: '.',
        v: value,
    });
};

export const maskPercentage = value => {
    return createDecimalFormatter({
        precision: 2,
        separator: '.',
        suffix: ' %',
        v: value,
    });
};

export const maskRoas = (roas => maskDecimal(roas / 100));
