import React, { forwardRef, useCallback } from 'react';

import { Radio } from 'antd';

const RadioGroup = forwardRef(({
    value,
    options,
    onChange,
    ...otherProps
}, ref) => {

    const handleRadioChange = useCallback(input => {
        const selectedValue = input.target.value;
        onChange(selectedValue);
    }, [onChange]);

    return (
        <Radio.Group
            ref={ref}
            value={value}
            onChange={handleRadioChange}
            {...otherProps}
        >
            {options.map(option => {
                return (
                    <Radio
                        key={option.key}
                        value={option.key}
                    >
                        {option.label}
                    </Radio>
                );
            })}
        </Radio.Group>
    );
});

export default RadioGroup;
