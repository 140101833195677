import { message } from 'antd';
import axios from 'axios';
import { FORM_ERROR } from 'final-form';

import ErrorEmptyShippingLabel from '~/errors/error-empty-shipping-label';
import i18n from '~/translations';

const errorCodes = {
    30: response => ({
        [FORM_ERROR]: i18n.t('ERROR_HELPER:ERROR_CODES_FORM_ERROR'),
    }),
    101: response => {
        message.error(i18n.t('ERROR_HELPER:ERROR_CODES_INVALID_CREDENTIALS'));
    },
    100: response => {
        message.error(i18n.t('ERROR_HELPER:ERROR_CODES_NOT_HAVE_PERMISSION'));
    },
    campanha_de_exemplo_already_exists_with: response => {
        message.info(i18n.t('ERROR_HELPER:ERROR_CODES_CAMPAIGN_ALREADY_EXIST'));
    },
    'the_ad_group_name_must_be_unique.': response => {
        message.info(i18n.t('ERROR_HELPER:ERROR_CODES_GROUP_ALREADY_EXISTS'), 8);
    },
};

function defaultErrorMessage(response, defaultMessage) {
    const { status } = response;
    if ([412, 422].includes(status)) {
        message.error(i18n.t('ERROR_HELPER:DEFAULT_ERROR_MESSAGE_OPERATION_NOT_COMPLETED'));
        return {
            [FORM_ERROR]: (i18n.t('ERROR_HELPER:DEFAULT_ERROR_MESSAGE_OPERATION_NOT_COMPLETED')),
        };
    }

    if (status === 403) {
        message.error(i18n.t('ERROR_HELPER:DEFAULT_ERROR_MESSAGE_UNAUTHORIZED_OPERATION'));
        return {
            [FORM_ERROR]: i18n.t('ERROR_HELPER:DEFAULT_ERROR_MESSAGE_UNAUTHORIZED_OPERATION'),
        };
    }

    if (status === 404) {
        message.error(i18n.t('ERROR_HELPER:DEFAULT_ERROR_MESSAGE_FEATURE_REQUEST_NOT_AVAILABLE'));
        return {
            [FORM_ERROR]: i18n.t('ERROR_HELPER:DEFAULT_ERROR_MESSAGE_FEATURE_REQUEST_NOT_AVAILABLE'),
        };
    }

    message.error(defaultMessage);
    return {
        [FORM_ERROR]: defaultMessage,
    };
}

const getErrorCode = response => {
    const responseData = response.data;

    if (responseData?.error?.code) return responseData.error.code;

    if (responseData?.message) return responseData.message.trim().toLocaleLowerCase().replace(/\s/g, '_');

    return null;
};

const getErrorMessage = response => {
    const responseData = response.data;

    if (responseData?.message) return responseData.message;

    return null;
};

/**
 * Faz a extração da mensagem de erro de acordo com os vários cenários que temos
 * @param {Error} err
 */
export function extractRequestError(err, defaultMessage) {
    if (axios.isCancel(err)) {
        return {};
    }

    if (__DEV__) {
        console.warn(err);
    }

    const { response } = err;

    if (err instanceof ErrorEmptyShippingLabel) {
        message.error(i18n.t('ERROR_HELPER:EXTRACT_REQUEST_ERROR_UNABLE_PRINT_LABELS'));
        return {};
    }

    if (!response) {
        message.error(i18n.t('ERROR_HELPER:EXTRACT_REQUEST_ERROR_YOU_APPEAR_OFFLINE'));
        return {};
    }

    const errorCode = getErrorCode(response);
    const errorMessage = (
        defaultMessage === null || defaultMessage === undefined
    ) ? getErrorMessage(response) : defaultMessage;
    const errorFunc = errorCodes[errorCode];
    if (typeof errorFunc === 'function') {
        return errorFunc(response);
    }

    return defaultErrorMessage(response, errorMessage);
}
