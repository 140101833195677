import i18n from '~/translations';

export default async (axios, searchValue, page, limit, params = {}) => {
    //   // const response = await axios.get('/services/anyenvios/api/cost-centers', {
    // params: {
    //  page,
    //  name: searchValue,
    //  },
    // });

    const response = {
        data: {
            totalPages: 0,
            number: 0,
            content: [
                {
                    id: 1,
                    name: i18n.t('INPUT_SEARCH:EASTER'),
                },
                {
                    id: 2,
                    name: i18n.t('INPUT_SEARCH:CHRISTMAS'),
                },
                {
                    id: 3,
                    name: i18n.t('INPUT_SEARCH:JUNE_CELEBRATION'),
                },
                {
                    id: 4,
                    name: i18n.t('INPUT_SEARCH:WINTER'),
                },
                {
                    id: 5,
                    name: i18n.t('INPUT_SEARCH:SUMMER'),
                },
            ],
        },
    };

    const { data } = response;
    const { totalPages, number, content } = data;
    return {
        metadata: {
            page: number,
            pages: totalPages,
        },
        result: content.map(item => ({
            label: item.name,
            key: item.id,
            item,
        })),
    };
};
