import React from 'react';

import { maskMoney } from '~/helpers/mask-helper';
import { parseDecimal } from '~/helpers/parser-helper';
import { validateNotEmpty } from '~/helpers/validations';
import i18n from '~/translations';

import Input from '../Input';
import styles from './MonetaryInputVariations.module.scss';

export const MONETARY_VARIATIONS = {
    BETWEEN: i18n.t('MONETARY_INPUT_VARIATIONS:BETWEEN'),
    EQUAL: i18n.t('MONETARY_INPUT_VARIATIONS:EQUAL'),
    BIGGER_THAN: i18n.t('MONETARY_INPUT_VARIATIONS:BIGGER_THAN'),
    SMALLER_THAN: i18n.t('MONETARY_INPUT_VARIATIONS:SMALLER_THAN'),
};

const MonetaryInputVariations = ({ fieldName, variation }) => {
    if (variation === 'BETWEEN') {
        return (
            <div className={styles.betweenInput}>
                <Input.Field
                    required
                    name={`${fieldName}.startValue`}
                    maxLength={20}
                    format={maskMoney}
                    parse={parseDecimal}
                    validate={validateNotEmpty}
                    size="default"
                />
                <Input.Field
                    required
                    name={`${fieldName}.endValue`}
                    maxLength={20}
                    format={maskMoney}
                    parse={parseDecimal}
                    validate={validateNotEmpty}
                    size="default"
                />
            </div>
        );
    }

    return (
        <Input.Field
            required
            name={`${fieldName}.value`}
            maxLength={20}
            format={maskMoney}
            parse={parseDecimal}
            validate={validateNotEmpty}
            size="default"
        />
    );
};

export default MonetaryInputVariations;
