/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react/jsx-no-bind */
import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';

import {
    Typography, Card, Modal, Space,
} from 'antd';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import Input from '~/components/Input';
import SimpleListContent from '~/components/ListPageContent/SimpleListContent';
import Spinner from '~/components/Spinner';
import Table from '~/components/Table';
import { wrapDialog } from '~/helpers/dialog-helper';
import useAxios from '~/hooks/use-axios';

import styles from './PricingHistoryDialog.module.scss';

const { Column } = Table;

const MODAL_STYLE = { top: '10dvh' };
const { Title } = Typography;

const PricingHistoryDialog = ({
    data,
    onCancel,
    maskUpdate,
    renderMoneyColumn,
    renderRuleStrategy,
    renderUpdateSituation,
    ...others
}) => {
    const axios = useAxios({
        baseURL: process.env.REACT_APP_URL_API_GO,
    });
    const $ref = useRef(null);
    const { t } = useTranslation('PRICING_HISTORY_DIALOG');
    const [loading, setLoading] = useState(false);

    const requestDataSource = useCallback(
        async (filterValues, orderValues, page, limit) => {
            try {
                setLoading(true);

                const { datePeriod } = filterValues;

                const params = {
                    page,
                    limit,
                };

                if (datePeriod) {
                    params.dateStart = moment(datePeriod[0]).format('YYYY-MM-DD');
                    params.dateEnd = moment(datePeriod[1]).format('YYYY-MM-DD');
                }

                const response = await axios.get(`v1/rules/${data.ruleId}/history`, {
                    params,
                });

                return response;
            } finally {
                setLoading(false);
            }
        },
        [axios, data],
    );

    const FILTER_INPUTS = useMemo(() => {
        const filters = [
            {
                label: t('DATE_FILTER_LABEL'),
                name: 'datePeriod',
                column: { lg: 12, xl: 6, xxl: 5 },
                component: (
                    <Input.Field
                        allowClear={false}
                        name="datePeriod"
                        type="daterange"
                        label={t('DATE_FILTER_LABEL')}
                        dateFormat={t('DATE_FORMAT')}
                    />
                ),
            },
        ];

        return filters;
    }, [t]);

    const defaultDate = { datePeriod: [moment().subtract(1, 'months'), moment()] };

    const renderUpdateType = useCallback((_, item) => {
        switch (item.eventType) {
            case 'changed_rule':
                return t('CHANGED_RULE');
            case 'changed_price_to_win_buybox':
                return t('CHANGED_PRICE_TO_WIN_BUYBOX');
            case 'changed_price_increase_margin':
                return t('CHANGED_PRICE_INCREASE_MARGIN');
            default:
                return t('CREATED_RULE');
        }
    }, [t]);

    const renderBoolean = useCallback(value => {
        switch (value) {
            case true:
                return t('TRUE');
            case false:
                return t('FALSE');
            default:
                return '-';
        }
    }, [t]);

    return (
        <Modal
            {...others}
            title={null}
            footer={null}
            width="80%"
            maskClosable
            destroyOnClose
            style={MODAL_STYLE}
            onCancel={onCancel}
        >
            <Spinner spinning={loading}>
                <Space direction="vertical" className={styles.space} size={20}>
                    <Title level={4} type="secondary">
                        {t('DIALOG_TITLE')}
                    </Title>
                    <Card size="small" bordered={false}>
                        <SimpleListContent
                            requestDataSource={requestDataSource}
                            id="pricing-history"
                            filterInputs={FILTER_INPUTS}
                            ref={$ref}
                            defaultFilters={defaultDate}
                            tableScroll={{ y: '50dvh' }}
                        >
                            <Column
                                title={t('COLUMN_TITLE_UPDATED')}
                                key="lastUpdate"
                                dataIndex="lastUpdate"
                                render={maskUpdate}
                                align="center"
                                width={120}
                            />
                            <Column
                                title={t('COLUMN_TITLE_PREVIOUS_PRICE')}
                                key="lastPrice"
                                dataIndex="lastPrice"
                                render={renderMoneyColumn}
                                csvRender={renderMoneyColumn}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_RIVAL_PRICE')}
                                key="rivalPrice"
                                dataIndex="rivalPrice"
                                render={renderMoneyColumn}
                                csvRender={renderMoneyColumn}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_MIN_PRICE')}
                                key="minPrice"
                                dataIndex="minPrice"
                                render={renderMoneyColumn}
                                csvRender={renderMoneyColumn}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_MAX_PRICE')}
                                key="maxPrice"
                                dataIndex="maxPrice"
                                render={renderMoneyColumn}
                                csvRender={renderMoneyColumn}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_NEW_PRICE')}
                                key="currentPrice"
                                dataIndex="currentPrice"
                                render={renderMoneyColumn}
                                csvRender={renderMoneyColumn}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_STRATEGY')}
                                key="ruleStrategy"
                                dataIndex="ruleStrategy"
                                render={renderRuleStrategy}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_UPDATE_TYPE')}
                                key="eventType"
                                dataIndex="eventType"
                                render={renderUpdateType}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_REQUIRES_CONFIRMATION')}
                                key="requiresConfirmation"
                                dataIndex="requiresConfirmation"
                                render={renderBoolean}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_OVERRIDE_AUTHORITY')}
                                key="overrideAuthority"
                                dataIndex="overrideAuthority"
                                render={renderBoolean}
                                align="center"
                                width={100}
                            />
                            <Column
                                title={t('COLUMN_TITLE_SITUATION')}
                                key="logStatus"
                                dataIndex="logStatus"
                                render={renderUpdateSituation}
                                align="center"
                                width={90}
                            />
                        </SimpleListContent>
                    </Card>
                </Space>
            </Spinner>
        </Modal>
    );
};

export default wrapDialog(PricingHistoryDialog);
