import React, {
    memo, useRef, useCallback, useMemo, useState,
} from 'react';

import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';

import { wrapDialog } from '~/helpers/dialog-helper';
import { downloadCsv } from '~/helpers/file-helper';
import { parseJsonObjectToCsv } from '~/helpers/papaparse-helper';
import useClassNames from '~/hooks/use-classnames';
import i18n from '~/translations';
import strings from '~/values/strings';

import ImgLoading from '../ImgLoading';
import Modal from '../Modal';
import Table from '../Table';
import styles from './PrintDialog.module.scss';

const textBoldComponent = { strong: <strong /> };

const exportCsvTextFile = async ({ dataSource, csvColums }) => {
    const mappedDataSource = dataSource.map(item => {
        return csvColums.reduce((acc, column) => {
            const value = get(item, column.dataIndex);

            return {
                ...acc,
                [column.title]: typeof column.render === 'function' ? column.render(value, item) : value,
            };
        }, {});
    });

    const csvData = await parseJsonObjectToCsv(mappedDataSource);
    await downloadCsv({
        filename: `${i18n.t('GLOBAL:FILE_NAME')}${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`,
        content: csvData,
    });
};

const PrintDialog = ({
    dataSource, title, tableColumns,
    className,
    tableRowKey,
    ...others
}) => {
    const $contentToPrint = useRef();
    const [exportingCsv, setExportingCsv] = useState(false);
    const { t } = useTranslation('PRINT_DIALOG');

    const renderLogoImage = () => {
        // aqui pode ser implementado a alteração do logotipo do sistema
        // para algum outro logotipo customizado
        return (
            <ImgLoading
                src={require('~/assets/logo-winnerbox.png').default}
                alt={t('SYSTEM_LOGO')}
                className={styles.logotipo}
            />
        );
    };

    const filteredColumns = useMemo(() => {
        return React.Children.toArray(tableColumns)
            .filter(column => {
                return !column.props.ignorePrint;
            })
            .map((column, index) => {
                return React.cloneElement(column, {
                    // eslint-disable-next-line react/no-array-index-key
                    key: `column-${index}`,
                    sorter: false,
                    ellipsis: false,
                });
            });
    }, [tableColumns]);

    const csvColums = useMemo(() => {
        return React.Children.toArray(filteredColumns)
            .map(column => {
                return {
                    title: column.props.title,
                    dataIndex: column.props.dataIndex,
                    render: column.props.csvRender,
                };
            });
    }, [filteredColumns]);

    const getContentToPrint = useCallback(() => {
        return $contentToPrint.current;
    }, []);

    const renderTrigger = useCallback(() => {
        return (
            <Button icon={<PrinterOutlined />} className={styles.optionButton}>
                {t('PRINT')}
            </Button>
        );
    }, [t]);

    const handleDownloadCsv = useCallback(async () => {
        try {
            setExportingCsv(true);
            await exportCsvTextFile({
                dataSource, csvColums,
            });
        } catch (err) {
            console.warn(err);
            message.error(t('GLOBAL:DOWNLOAD_ERROR'));
        } finally {
            setExportingCsv(false);
        }
    }, [dataSource, csvColums, t]);

    const modalClasses = useClassNames([styles.modal, className]);

    const totalOfRecordsValues = useMemo(() => ({
        recordsNumber: dataSource.length,
    }), [dataSource]);

    return (
        <Modal
            {...others}
            footer={null}
            closable
            width="90%"
            className={modalClasses}
        >
            <div className={styles.buttonsContainer}>
                <ReactToPrint
                    trigger={renderTrigger}
                    content={getContentToPrint}
                />
                <Button
                    onClick={handleDownloadCsv}
                    icon={<FileExcelOutlined />}
                    className={styles.optionButton}
                    loading={exportingCsv}
                >
                    CSV
                </Button>
            </div>
            <div ref={$contentToPrint} className={styles.contentToPrint}>
                <div className={styles.header}>
                    {renderLogoImage()}
                    <div className={styles.titleContainer}>
                        <h3>
                            {t('EMITTED_BY', { appName: strings.appName })}
                        </h3>
                        <h1>
                            {t('REPORT_OF', { name: title })}
                        </h1>
                    </div>
                </div>
                <Table
                    rowKey={tableRowKey}
                    dataSource={dataSource}
                    size="small"
                    scroll={null}
                    rowClassName={styles.tableRow}
                >
                    {filteredColumns}
                </Table>
                <br />
                <Trans
                    t={t}
                    i18nKey="TOTAL_OF_RECORDS"
                    components={textBoldComponent}
                    values={totalOfRecordsValues}
                />
            </div>
        </Modal>
    );
};

PrintDialog.propTypes = {
    tableRowKey: PropTypes.func,
};

export default wrapDialog(memo(PrintDialog));
