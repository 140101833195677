import React from 'react';

import { useTranslation } from 'react-i18next';

import { maskInteger } from '~/helpers/mask-helper';
import { parseInteger } from '~/helpers/parser-helper';
import { validateNumber } from '~/helpers/validations';
import i18n from '~/translations';

import Input from '../Input';
import styles from './NumericInputVariations.module.scss';

export const NUMERIC_VARIATIONS = {
    BETWEEN: i18n.t('NUMERIC_INPUT_VARIATIONS:BETWEEN'),
    EQUAL: i18n.t('NUMERIC_INPUT_VARIATIONS:EQUAL'),
    BIGGER_THAN: i18n.t('NUMERIC_INPUT_VARIATIONS:BIGGER_THAN'),
    SMALLER_THAN: i18n.t('NUMERIC_INPUT_VARIATIONS:SMALLER_THAN'),
};

const NumericInputVariations = ({ fieldName, variation }) => {
    const { t } = useTranslation('NUMERIC_INPUT_VARIATIONS');

    if (variation === 'BETWEEN') {
        return (
            <div className={styles.betweenInput}>
                <Input.Field
                    required
                    name={`${fieldName}.startValue`}
                    maxLength={20}
                    placeholder={t('VALUE')}
                    validate={validateNumber}
                    format={maskInteger}
                    parse={parseInteger}
                    size="default"
                />
                <Input.Field
                    required
                    name={`${fieldName}.endValue`}
                    maxLength={20}
                    placeholder={t('VALUE')}
                    validate={validateNumber}
                    format={maskInteger}
                    parse={parseInteger}
                    size="default"
                />
            </div>
        );
    }

    return (
        <Input.Field
            required
            name={`${fieldName}.value`}
            maxLength={20}
            placeholder={t('VALUE')}
            validate={validateNumber}
            format={maskInteger}
            parse={parseInteger}
            size="default"
        />
    );
};

export default NumericInputVariations;
