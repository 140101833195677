import React, {
    Suspense, lazy, useCallback, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from '~/components/PrivateRoute';
import StageIndicator from '~/components/StageIndicator';
import useAxios from '~/hooks/use-axios';
import useDidMountAndUpdate from '~/hooks/use-did-mount-and-update';
import thunks from '~/redux/thunks';
import { renderRoute } from '~/routes/manager';
import { allRoutes } from '~/routes/public';
import i18n from '~/translations';

import { getBrowserFingerprint } from '../browserfingerprint/fingerprint';
import AppLayout from './AppLayout';

const NotFoundPage = lazy(() => import('~/domains/errors/NotFoundPage'));

const hideSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
        setTimeout(() => {
            splashScreen.classList.add('splash-screen-hide');
            setTimeout(
                () => splashScreen.parentNode.removeChild(splashScreen),
                1000,
            );
        });
    }
};

const Layout = ({ loadAuthenticationData, history, setAuthenticationData }) => {
    const axios = useAxios();

    const { t } = useTranslation('LAYOUT');
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadData = useCallback(async () => {
        try {
            await loadAuthenticationData();
        } catch (ex) {
            console.warn(ex);
        }
    }, [loadAuthenticationData]);

    const loadSession = useCallback(async () => {
        try {
            const fingerprint = await getBrowserFingerprint();

            const response = await axios.post(`${process.env.REACT_APP_AAUTH_HOST}/session`, { fingerprint });
            const { data } = response;

            if (data.token !== null && data.token !== undefined) {
                await setAuthenticationData({
                    ...response.data,
                    companyId: response.data.company_id,
                    companyName: response.data.company_name,
                });
            }
        } catch (ex) {
            console.warn(ex);
        }
    }, [axios, setAuthenticationData]);

    const initializeData = useCallback(async () => {
        setLoading(true);

        await loadSession();
        await loadData();

        setLoading(false);
        setReady(true);
    }, [loadSession, loadData]);

    useDidMountAndUpdate(() => {
        if (ready) {
            hideSplashScreen();
        } else {
            initializeData();
        }
    }, [ready]);

    if (!ready) return i18n.t('LAYOUT:LOADING_USERS');

    return (
        <Suspense fallback={<div> {t('LOADING')} </div>}>
            {loading}
            <Switch>
                {allRoutes.map((route, index) => {
                    return renderRoute(route, index);
                })}
                <PrivateRoute path="/app" component={AppLayout} />
                <Route component={NotFoundPage} />
            </Switch>
            <StageIndicator />
        </Suspense>
    );
};

const mapDispatchToProps = ({
    setAuthenticationData: thunks.user.setAuthenticationData,
    loadAuthenticationData: thunks.user.loadAuthenticationData,
});

export default connect(null, mapDispatchToProps)(Layout);
