import React from 'react';

import { useTranslation } from 'react-i18next';

import { validateNotEmpty } from '~/helpers/validations';
import i18n from '~/translations';

import Input from '../Input';

export const TEXT_VARIATIONS = {
    EQUAL: i18n.t('TEXT_INPUT_VARIATIONS:EQUAL'),
    CONTAIN: i18n.t('TEXT_INPUT_VARIATIONS:CONTAIN'),
};

const TextInputVariations = ({ fieldName }) => {
    const { t } = useTranslation('TEXT_INPUT_VARIATIONS');

    return (
        <Input.Field
            required
            type="text"
            name={`${fieldName}.text`}
            placeholder={t('ENTER_A_TEXT')}
            validate={validateNotEmpty}
            size="default"
        />
    );
};

export default TextInputVariations;
