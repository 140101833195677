import React, { useCallback, useMemo } from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Input as AntdInput, Button as AntdButton,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { wrapFormFieldArray } from '~/helpers/form-helper';
import { validateNotEmpty } from '~/helpers/validations';
import { LOCAL_OPERATOR_OPTIONS } from '~/values/enums';

import Button from '../Button';
import Input from '../Input';
import InputSearch from '../InputSearch';
import DateInputVariations, { DATE_VARIATIONS } from './DateInputVariations';
import MonetaryInputVariations, { MONETARY_VARIATIONS } from './MonetaryInputVariations';
import NumericInputVariations, { NUMERIC_VARIATIONS } from './NumericInputVariations';
import TextInputVariations, { TEXT_VARIATIONS } from './TextInputVariations';
import styles from './VariableFilterFieldArray.module.scss';

const createOptionsFromEnum = values => Object.keys(values).map(key => ({
    key,
    label: values[key],
}));

export const INPUT_TYPES = {
    date: { key: 'date', variations: createOptionsFromEnum(DATE_VARIATIONS) },
    text: { key: 'text', variations: createOptionsFromEnum(TEXT_VARIATIONS) },
    monetary: { key: 'monetary', variations: createOptionsFromEnum(MONETARY_VARIATIONS) },
    numeric: { key: 'numeric', variations: createOptionsFromEnum(NUMERIC_VARIATIONS) },
    select: { key: 'select', variations: null },
    search: { key: 'search', variations: null },
};

const FilterGroup = ({
    fieldName,
    fieldValue,
    position,
    typeOptions,
    onRemove,
}) => {
    const { t } = useTranslation('VARIABLE_FILTER_FIELD_ARRAY');

    const typeConfig = fieldValue.type?.item;
    const variationsOptions = INPUT_TYPES[typeConfig?.filterType]?.variations || [];

    const handleGroupRemoval = useCallback(() => {
        onRemove(position);
    }, [onRemove, position]);

    const renderSelectionInputs = (type, variation) => {
        if (type === INPUT_TYPES.search.key) {
            return (
                <div className={styles.valueInput}>
                    <InputSearch.Field
                        required
                        name={`${fieldName}.options`}
                        searchType={typeConfig.searchType}
                        placeholder={t('TYPE_SELECT_PLACEHOLDER')}
                        baseUrl={process.env.REACT_APP_URL_API_GO}
                        validate={validateNotEmpty}
                        size="default"
                    />
                </div>
            );
        }

        if (type === INPUT_TYPES.select.key) {
            return (
                <div className={styles.valueInput}>
                    <Input.Field
                        required
                        type="select"
                        name={`${fieldName}.options`}
                        options={typeConfig.options}
                        validate={validateNotEmpty}
                        placeholder={t('TYPE_SELECT_PLACEHOLDER')}
                        size="default"
                    />
                </div>
            );
        }

        if (!variation) return null;

        if (type === INPUT_TYPES.date.key) {
            return (
                <div className={styles.dateInput}>
                    <DateInputVariations fieldName={fieldName} variation={variation.key} />
                </div>
            );
        }

        if (type === INPUT_TYPES.text.key) {
            return (
                <div className={styles.valueInput}>
                    <TextInputVariations fieldName={fieldName} />
                </div>
            );
        }

        if (type === INPUT_TYPES.monetary.key) {
            return (
                <div className={styles.doubleInput}>
                    <MonetaryInputVariations fieldName={fieldName} variation={variation.key} />
                </div>
            );
        }

        if (type === INPUT_TYPES.numeric.key) {
            return (
                <div className={styles.doubleInput}>
                    <NumericInputVariations fieldName={fieldName} variation={variation.key} />
                </div>
            );
        }

        return null;
    };

    const renderInputField = type => {
        if (!variationsOptions.length) return null;

        return (
            <div className={styles.variationInput}>
                <Input.Field
                    required
                    type="select"
                    name={`${fieldName}.variation`}
                    options={variationsOptions}
                    validate={validateNotEmpty}
                    placeholder={t('SELECT_CONDITION_PLACEHOLDER')}
                    allowClear
                    size="default"
                />
            </div>
        );
    };

    return (
        <AntdInput.Group compact key={fieldValue.type?.key}>
            <div className={styles.typeInput}>
                <Input.Field
                    required
                    type="select"
                    name={`${fieldName}.type`}
                    options={typeOptions}
                    validate={validateNotEmpty}
                    placeholder={t('SELECT_ATTRIBUTE_PLACEHOLDER')}
                    allowClear
                    size="default"
                />
            </div>
            {fieldValue.type ? renderInputField(fieldValue.type.item.filterType) : null}
            {renderSelectionInputs(typeConfig?.filterType, fieldValue.variation)}
            <AntdButton
                icon={<CloseOutlined />}
                onClick={handleGroupRemoval}
                danger
                className={styles.removeFilterButton}
                size="default"
            />
        </AntdInput.Group>
    );
};

const VariableFilterFieldArray = ({ fields, typeOptions }) => {
    const { value: fieldsValues } = fields;

    const filteredOptions = useMemo(() => {
        if (!fieldsValues) return typeOptions;
        return typeOptions.filter(type => !fieldsValues.some(field => field.type?.key === type.key));
    }, [fieldsValues, typeOptions]);

    const removeItem = useCallback(position => {
        fields.remove(position);
    }, [fields]);

    const addNewItem = useCallback(event => {
        event.stopPropagation();
        fields.push({
            logicalOperator: LOCAL_OPERATOR_OPTIONS[0],
        });
    }, [fields]);

    const mapFields = (fieldName, position) => {
        const fieldValue = fieldsValues[position];
        const showConjunction = position < (fields.length - 1);

        return (
            <div className={styles.conjunctionContainer} key={fieldName}>
                <div>
                    <FilterGroup
                        fieldValue={fieldValue}
                        fieldName={fieldName}
                        position={position}
                        typeOptions={filteredOptions}
                        onRemove={removeItem}
                    />
                </div>
                {showConjunction ? (
                    <div className={styles.logicalOperatorInput}>
                        <Input.Field
                            required
                            type="select"
                            name={`${fieldName}.logicalOperator`}
                            options={LOCAL_OPERATOR_OPTIONS}
                            validate={validateNotEmpty}
                            size="default"
                        />
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <div className={styles.content}>
            <div className={styles.fieldsContainer}>
                {fields.length ? fields.map(mapFields) : null}
            </div>
            <Button onClick={addNewItem}>
                <PlusOutlined />
            </Button>
        </div>
    );
};

VariableFilterFieldArray.propTypes = {
    typeOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        item: PropTypes.shape({
            filterType: PropTypes.oneOf(['date', 'text', 'monetary', 'numeric', 'select', 'search']),
            options: PropTypes.array,
            searchType: PropTypes.string,
        }),
    })),
};

export default wrapFormFieldArray(VariableFilterFieldArray);
