import moment from 'moment-timezone';

import i18n from '~/translations';

import store from '../redux/store';
// import UserPreferences from '~/helpers/user-preferences-helper';

export function tokenInterceptor(requestConfig) {

    const { token } = store.getState();

    // console.log(store.getState());

    // const token = UserPreferences.getItem('TOKEN_KEY');

    if (token && !requestConfig.headers.Authorization) {
        // eslint-disable-next-line no-param-reassign
        requestConfig.headers.Authorization = `Bearer ${token}`;

        // if (company !== null && company !== undefined) {
        //     // eslint-disable-next-line no-param-reassign
        //     requestConfig.headers['x-company-id'] = company;
        // }

        // // eslint-disable-next-line no-param-reassign
        // requestConfig.headers['Access-Control-Allow-Origin'] = '*';
    }
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers.timezone = moment.tz.guess();

    // eslint-disable-next-line no-param-reassign
    requestConfig.headers['Accepted-Language'] = i18n.t('GLOBAL:ACCEPTED_LANGUAGE_HEADER');

    return requestConfig;
}
