import React, { useMemo } from 'react';

import { Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';

import useClassNames from '~/hooks/use-classnames';
import colors from '~/values/colors';

import styles from './IconButton.module.scss';

const IconButton = ({
    to, Icon, size, color,
    title, className,
    ...others
}) => {

    const Container = to ? Link : Button;

    const iconStyle = useMemo(() => ({
        fontSize: size,
        color,
    }), [color, size]);

    const containerClasses = useClassNames([
        className,
        styles.container,
        'ant-btn ant-btn-link',
    ]);

    return (
        <Tooltip placement="top" title={title}>
            <Container
                to={to}
                {...others}
                className={containerClasses}
            >
                <Icon
                    style={iconStyle}
                />
            </Container>
        </Tooltip>
    );
};

IconButton.defaultProps = {
    color: colors.emphasisColor,
    size: 16,
};

export default IconButton;
