import React, { useCallback, useState } from 'react';

import { message, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';
import Icon from '~/components/Icon';
import Modal from '~/components/Modal';
import RadioGroup from '~/components/RadioGroup';
import Spinner from '~/components/Spinner';
import { wrapDialog } from '~/helpers/dialog-helper';
import { wrapForm } from '~/helpers/form-helper';
import { maskMoney } from '~/helpers/mask-helper';
import useAsync from '~/hooks/use-async';
import useAxios from '~/hooks/use-axios';
import useClassNames from '~/hooks/use-classnames';
import i18n from '~/translations';

import PricingDialog from './PricingDialog';
import styles from './PricingRuleDialog.module.scss';

const MODAL_STYLE = { top: 100 };

const OPTIONS = [
    {
        key: 'CHANGE_PRICE',
        label: i18n.t('PRICING_RULE_DIALOG:CHANGE_PRICING_RULE'),
    },
    {
        key: 'UNDO_AND_STAY',
        label: i18n.t('PRICING_RULE_DIALOG:UNDO_AND_STAY_WITH_CURRENT_RULES'),
    },
];

const PricingRuleDialog = ({
    data, onCancel, onCancelPricingRuleDialog, ...others
}) => {
    const axios = useAxios({
        baseURL: process.env.REACT_APP_URL_API_GO,
    });
    const { t } = useTranslation('PRICING_RULE_DIALOG');
    const [selectedOption, setSelectedOption] = useState(null);

    const {
        id: ruleLogId, minPrice, maxPrice, ruleId,
    } = data;

    const returnButtonClasses = useClassNames([styles.submitButtons, styles.cancelButton]);

    const {
        call: changeToCurrentValues,
        loading,
    } = useAsync(async newStatus => {
        try {
            await axios.put(`/v1/rules/${ruleId}/logs/${ruleLogId}/undo`);
            onCancel();
            onCancelPricingRuleDialog();
            message.success(t('UPDATE_HISTORY_PAGE:SUCCESS_ON_UNDO_RULE'));
        } catch (error) {
            message.error(t('UPDATE_HISTORY_PAGE:ERROR_ON_CHANGE_RULE'));
        }
    }, [axios, ruleId, ruleLogId, onCancel, onCancelPricingRuleDialog, t]);

    const openPricingDialog = useCallback(async adData => {
        PricingDialog.show({
            data: adData,
            onCancelPricingDialog: onCancelPricingRuleDialog,
        });
        onCancel();
    }, [onCancel, onCancelPricingRuleDialog]);

    const onConfirmClick = useCallback(async () => {
        if (selectedOption === 'UNDO_AND_STAY') {
            changeToCurrentValues();
            return;
        }

        openPricingDialog([data]);
    }, [selectedOption, changeToCurrentValues, openPricingDialog, data]);

    // TODO: Remover quando for ajustado retorno da API
    const renderMoney = value => maskMoney(value, { ...data, currencyCode: 'BRL', countryCode: 'BR' });

    const undoPricingMessage = t('PRICING_UNDO_MESSAGE')
        .replace('XX', renderMoney(minPrice))
        .replace('YY', renderMoney(maxPrice));

    return (
        <Modal
            {...others}
            title={null}
            closable
            destroyOnClose
            width={680}
            style={MODAL_STYLE}
            onCancel={loading ? null : onCancel}
            footer={null}
        >
            <Spinner spinning={loading}>
                <div className={styles.pricingRuleDialogContainer}>
                    <Space direction="vertical" className={styles.space} size={25}>
                        <div className={styles.warningContent}>
                            <i className={styles.alertIcon}>
                                <Icon family="MaterialIcons" name="errorOutline" size={100} />
                            </i>
                            <p>{undoPricingMessage}</p>
                            <p>{t('PRICING_REVIEW_SUGGESTION')}</p>
                        </div>
                        <span>{t('DECISION_QUESTION')}</span>
                        <RadioGroup value={selectedOption} onChange={setSelectedOption} options={OPTIONS} />
                        <div className={styles.buttonContainer}>
                            <Button type="default" className={returnButtonClasses} onClick={onCancel}>
                                {t('RETURN')}
                            </Button>
                            <Button
                                disabled={!selectedOption}
                                className={styles.submitButtons}
                                onClick={onConfirmClick}
                            >
                                {t('CONFIRM')}
                            </Button>
                        </div>
                    </Space>
                </div>
            </Spinner>
        </Modal>
    );
};

export default wrapDialog(wrapForm(PricingRuleDialog));
