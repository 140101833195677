export const parseJsonObjectToCsv = async jsonArray => {
    const Papa = await import('papaparse');
    return Papa.unparse(jsonArray, {
        quotes: false, // or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ';',
        header: true,
        newline: '\r\n',
        skipEmptyLines: false, // or 'greedy',
        columns: null, // or array of strings
    });
};
