import React from 'react';

import { Tag } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './UpdateSituationTag.module.scss';

const UpdateSituationTag = ({ data }) => {
    const { t } = useTranslation('UPDATE_HISTORY_PAGE');

    const { logStatus } = data;

    const getIconColor = () => {
        switch (logStatus) {
            case 'applied':
                return styles.applied;
            case 'canceled':
                return styles.canceled;
            case 'pending':
                return styles.pending;
            case 'undone':
                return styles.undone;
            case 'error':
                return styles.error;
            default:
                return styles;
        }
    };

    const getDescription = () => {
        switch (logStatus) {
            case 'applied':
                return t('APPLIED');
            case 'canceled':
                return t('CANCELED');
            case 'pending':
                return t('PENDING');
            case 'undone':
                return t('UNDONE');
            case 'error':
                return t('ERROR');
            default:
                return '-';
        }
    };

    const renderTag = () => {
        return <Tag className={classNames([styles.tag, getIconColor()])}>{getDescription()}</Tag>;
    };

    return <>{renderTag()}</>;
};

export default UpdateSituationTag;
