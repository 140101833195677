import enUS from 'antd/lib/locale-provider/en_US';
import esES from 'antd/lib/locale-provider/es_ES';
import ptBR from 'antd/lib/locale-provider/pt_BR';

import i18n from '~/translations';

const localeOptions = {
    EN: enUS,
    ES: esES,
    PT: ptBR,
};

const locale = localeOptions[i18n.t('GLOBAL:LANGUAGE')] || localeOptions.PT;

export const AntLocale = {
    ...locale,
    Pagination: {
        ...locale.Pagination,
        items_per_page: i18n.t('LOCALE_HELPER:ITEMS_PER_PAGE'),
    },
    Table: {
        ...locale.Table,
        filterReset: i18n.t('LOCALE_HELPER:FILTER_RESET'),
        sortTitle: i18n.t('LOCALE_HELPER:SORT_TITLE'),
    },
};
