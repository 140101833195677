import React, { useCallback, useState } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import {
    Popover, Checkbox, Button as AntdButton, Divider,
} from 'antd';
import _isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import colors from '~/values/colors';

import Button from '../Button';
import styles from './ConfigureFilterOption.module.scss';

const { Group: CheckboxGroup } = Checkbox;

const ICON_STYLE = {
    color: colors.textOnPrimary,
};

const ConfigureFilterOption = ({
    options,
    disabled,
    initialValues,
    selectedValues,
    onChangeValues,
}) => {
    const [pendingOptions, setPendingOptions] = useState(initialValues);
    const { t } = useTranslation('CONFIGURE_FILTER_OPTION');

    const handleSelectionChange = useCallback(newOptions => {
        setPendingOptions(newOptions);
    }, []);

    const handleSelectionCancel = useCallback(() => {
        setPendingOptions(selectedValues);
    }, [selectedValues]);

    const handleSelectionConfirm = useCallback(() => {
        onChangeValues(pendingOptions);
    }, [onChangeValues, pendingOptions]);

    const onVisibleChange = useCallback(visible => {
        if (!visible) {
            handleSelectionCancel();
        }
    }, [handleSelectionCancel]);

    const renderContent = () => {
        return (
            <div>
                <CheckboxGroup
                    value={pendingOptions}
                    onChange={handleSelectionChange}
                >
                    {options.map(filterLabel => (
                        <div key={filterLabel}>
                            <Checkbox value={filterLabel}>{filterLabel}</Checkbox>
                        </div>
                    ))}
                </CheckboxGroup>
                <Divider className={styles.actionsDivider} />
                <div className={styles.actionsContainer}>
                    <AntdButton
                        block
                        size="small"
                        type="primary"
                        onClick={handleSelectionConfirm}
                        disabled={_isEqual(pendingOptions, selectedValues)}
                    >
                        {t('APPLY')}
                    </AntdButton>
                </div>
            </div>
        );
    };

    return (
        <Popover
            trigger="click"
            placement="bottom"
            disabled={disabled}
            content={renderContent()}
            title={t('SELECT_FILTERS')}
            onVisibleChange={onVisibleChange}
        >
            <Button
                fontColor={null}
                disabled={disabled}
                title={t('HIDE_SHOW_FILTERS')}
                icon={<FilterOutlined style={ICON_STYLE} />}
            />
        </Popover>
    );
};

export default ConfigureFilterOption;
