import React, { lazy } from 'react';

import { Redirect } from 'react-router-dom';

import { getRoutesArray } from './manager';

const LoginPage = lazy(() => import('~/domains/signin/LoginPage'));
// const ForgotPasswordPage = lazy(() => import('~/domains/forgotPassword/ForgotPasswordPage'));
const LoginCompanyPage = lazy(() => import('~/domains/companies/LoginCompanyPage'));
const ChangePasswordPage = lazy(() => import('~/domains/changePassword/ChangePasswordPage'));

const ROUTES = [
    {
        path: '/',
        component: () => (
            <Redirect to="/app" />
        ),
        exact: true,
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/companies',
        component: LoginCompanyPage,
    },
    {
        path: '/change-password',
        component: ChangePasswordPage,
    },
    // {
    //     path: '/forgot-password',
    //     component: ForgotPasswordPage,
    // },
];

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});

export default ROUTES;
