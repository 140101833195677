/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    useState,
    useCallback,
    useMemo,
    forwardRef,
    useImperativeHandle,
} from 'react';

import { ProjectOutlined, PrinterOutlined } from '@ant-design/icons';
import {
    Popover, Checkbox, Collapse, Col, Row,
} from 'antd';
import pick from 'lodash/pick';
import _union from 'lodash/union';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import BrowserStorageHelper from '~/helpers/browser-storage-helper';
import { extractRequestError } from '~/helpers/error-helper';
import { hasValue, wrapForm } from '~/helpers/form-helper';
import HistoryHelper from '~/helpers/history-helper';
import { areObjectsEqual } from '~/helpers/validations';
import useDidMount from '~/hooks/use-did-mount';
import colors from '~/values/colors';
import { DEFAULT_PAGE_SIZE, DEFAULT_TABLE_PAGINATION, LOCAL_OPERATOR_OPTIONS } from '~/values/enums';

import Alert from '../Alert';
import Button from '../Button';
import ContentPage from '../ContentPage';
import FilterContainer from '../FilterContainer';
import Form from '../Form';
import Table from '../Table';
import VariableFilterFieldArray from '../VariableFilterFieldArray';
import ConfigureFilterOption from './ConfigureFilterOption';
import PrintDialog from './PrintDialog';
import styles from './styles.module.scss';

const { Group: CheckboxGroup } = Checkbox;
const { Panel } = Collapse;

const ICON_STYLE = {
    color: colors.textOnPrimary,
};

function getStoredRequestParams(pageId) {
    const {
        filters, orders, page, limit,
    } = HistoryHelper.getQueries();

    if (!filters && !orders && !page && !limit) {
        const storageParams = BrowserStorageHelper.loadFilters(pageId);
        return pick(storageParams, ['filters', 'orders', 'page', 'limit']);
    }

    return {
        filters,
        orders,
        page,
        limit,
    };
}

function mapToColumnTitle(column) {
    return column.props.title;
}

function mapToFilterLabel(filter) {
    return filter.label;
}

function filterAlwaysVisibleColumn(column) {
    return column && !column.props.alwaysVisible;
}

function filterAlwaysVisibleFilter(filter) {
    return filter && !filter.alwaysVisible;
}

function filterDefaultSelectedColumn(column) {
    return column && !column.props.defaultHidden;
}

function filterDefaultSelectedFilter(filter) {
    return filter && !filter.defaultHidden;
}

const ListPageContent = forwardRef(
    (
        {
            id: pageId,
            title: pageTitle,
            children,
            defaultFilters,
            defaultOrders,
            tableScroll,
            basicFilter,
            advancedFilter,
            handleSubmit,
            renderContent,
            form,
            extraHeaderContent,
            requestDataSource,
            loading,
            tableRowSelection,
            tableRowKey,
            printable,
            configurable,
            tableExpandable,
            filterInputs,
            showPagination,
            requestListConfig,
            ...others
        },
        $ref,
    ) => {
        const { state: locationState } = useLocation();

        const [returningAlert, setReturningAlert] = useState(
            locationState?.returningAlert,
        );
        const [shouldShowAdvancedFilter, setShowAdvancedFilter] = useState(false);
        const [requesting, setRequesting] = useState(false);
        const [requestingListConfig, setRequestingListConfig] = useState(false);
        const [printing, setPrinting] = useState(false);
        const [dataSource, setDataSource] = useState([]);
        const [requestParams, setRequestParams] = useState({});
        const [tablePagination, setTablePagination] = useState(
            DEFAULT_TABLE_PAGINATION,
        );
        const [selectedColumns, setSelectedColumns] = useState([]);
        const [selectedFilters, setSelectedFilters] = useState([]);
        const [hasFilterConfigLoaded, setHasFilterConfigLoaded] = useState(false);
        const { t } = useTranslation('LIST_PAGE_CONTENT');
        const [variableFiltersOptions, setVariableFiltersOptions] = useState([]);

        const isRenderingCustomContent = Boolean(renderContent);
        const isLoading = requestingListConfig || requesting || loading;

        const { error, submitError } = form.getState();

        const filterOptions = useMemo(() => {
            if (!filterInputs) return [];

            return filterInputs
                .filter(filterAlwaysVisibleFilter)
                .map(mapToFilterLabel);
        }, [filterInputs]);

        const updateFilterValuesFromConfig = useCallback(
            items => {
                const filteredInputs = filterInputs
                    .filter(input => {
                        const { label } = input;
                        return !items.find(sel => sel === label);
                    })
                    .map(input => input.name);

                if (!filteredInputs.length) return;

                const { values } = form.getState();
                const filtersWithValue = Object.keys(values).reduce((keys, newKey) => {
                    return {
                        ...keys,
                        [newKey]: hasValue(values[newKey]),
                    };
                }, []);

                const filtersToUpdate = filteredInputs.filter(
                    filterName => filtersWithValue[filterName],
                );

                if (filtersToUpdate.length) {
                    form.batch(() => {
                        filtersToUpdate.forEach(filterName => {
                            form.change(filterName, undefined);
                        });
                    });

                    form.submit();
                }
            },
            [filterInputs, form],
        );

        const onFilterInputsConfigChange = useCallback(
            items => {
                if (Array.isArray(items)) {
                    setSelectedFilters(items);
                    BrowserStorageHelper.saveFiltersConfig(pageId, items);
                }

                updateFilterValuesFromConfig(items);
            },
            [pageId, updateFilterValuesFromConfig],
        );

        const onCloseReturningAlert = useCallback(() => {
            setReturningAlert(null);
        }, []);

        const columnsTitles = useMemo(() => {
            return React.Children.toArray(children)
                .filter(filterAlwaysVisibleColumn)
                .map(mapToColumnTitle);
        }, [children]);

        const onColumnsConfigChanged = useCallback(
            items => {
                if (Array.isArray(items)) {
                    setSelectedColumns(items);
                    BrowserStorageHelper.saveColumnsConfig(pageId, items);
                }
            },
            [pageId],
        );

        const filteredChildren = useMemo(() => {
            const { orders } = requestParams;

            return React.Children.toArray(children)
                .filter(Boolean)
                .filter(col => {
                    const { alwaysVisible, title } = col.props;
                    return alwaysVisible || selectedColumns.includes(title);
                })
                .map(column => {
                    if (!column.props.dataSorter) {
                        return column;
                    }
                    const sortOrder = orders && orders.field === column.props.dataSorter
                        ? orders.order
                        : false;
                    return React.cloneElement(column, {
                        sortOrder,
                        sorter: true,
                    });
                });
        }, [children, requestParams, selectedColumns]);

        const onPrintTableClick = useCallback(async () => {
            try {
                const { filters, orders } = requestParams;
                setPrinting(true);

                const maxLimit = 99999;

                const response = await requestDataSource(
                    filters || {},
                    orders,
                    null,
                    maxLimit,
                );
                if (!response) return null;
                const { content } = response.data;

                PrintDialog.show({
                    dataSource: content,
                    tableColumns: filteredChildren,
                    title: pageTitle,
                    tableRowKey,
                });
            } catch (err) {
                return await extractRequestError(
                    err,
                    t('PRINT_TABLE_ERROR'),
                );
            } finally {
                setPrinting(false);
            }
            return null;
        }, [
            requestParams,
            requestDataSource,
            pageTitle,
            filteredChildren,
            tableRowKey,
            t,
        ]);

        const handleAdvancedFilterChange = useCallback(() => {
            setShowAdvancedFilter(previous => !previous);
        }, []);

        const sendDataSourceRequest = useCallback(
            async (request, pagination) => {
                try {
                    const { filters = {}, orders = {} } = request;
                    const { page, limit } = pagination;
                    setRequesting(true);

                    let data;
                    try {
                        const response = await requestDataSource(
                            filters,
                            orders,
                            page - 1,
                            limit,
                        );

                        if (!response) return null;
                        data = response.data;
                    } catch (err) {
                        if (!err.response?.status === 404) {
                            throw err;
                        }
                        data = {
                            content: [],
                            totalElements: DEFAULT_TABLE_PAGINATION.total,
                            size: DEFAULT_TABLE_PAGINATION.limit,
                            number: DEFAULT_TABLE_PAGINATION.page - 1,
                            numberOfElements: DEFAULT_TABLE_PAGINATION.count,
                        };
                    }

                    const {
                        content, totalElements, size, number, numberOfElements,
                    } = data;

                    if (!isRenderingCustomContent) {
                        setTablePagination({
                            total: totalElements,
                            limit: size,
                            page: number + 1,
                            count: numberOfElements,
                        });
                    }

                    setDataSource(content);

                    HistoryHelper.updateQuery({
                        limit: size,
                        page: number + 1,
                        filters,
                        orders,
                    });
                } catch (err) {
                    return await extractRequestError(err);
                } finally {
                    setRequesting(false);
                }
                return null;
            },
            [requestDataSource, isRenderingCustomContent],
        );

        const onTableChange = useCallback(
            async (changedPagination, filters, sorter) => {
                const pagination = {
                    ...tablePagination,
                    page: changedPagination.current,
                    limit: changedPagination.pageSize,
                };

                const params = {
                    ...requestParams,
                    orders:
                        sorter.column && sorter.order
                            ? {
                                field: sorter.column.dataSorter,
                                order: sorter.order,
                            }
                            : null,
                };

                setTablePagination(pagination);
                setRequestParams(params);

                const response = await sendDataSourceRequest(params, pagination);

                BrowserStorageHelper.saveFilters(pageId, {
                    ...params,
                    limit: pagination.limit,
                    page: pagination.page,
                });

                return response;
            },
            [tablePagination, requestParams, sendDataSourceRequest, pageId],
        );

        const initializeSelectedColumns = useCallback(() => {
            const storedSelectColumns = BrowserStorageHelper.loadColumnsConfig(pageId);

            if (storedSelectColumns) {
                setSelectedColumns(storedSelectColumns);
            } else if (!isRenderingCustomContent) {
                const defaultSelectedColumns = children
                    .filter(filterDefaultSelectedColumn)
                    .map(mapToColumnTitle);

                setSelectedColumns(defaultSelectedColumns);
            }
        }, [children, isRenderingCustomContent, pageId]);

        const getSelectedFiltersFromRequestParam = useCallback(() => {
            const { filters } = getStoredRequestParams(pageId);
            const filterKeys = filters
                ? Object.keys(filters).filter(key => hasValue(filters[key]))
                : [];

            const inputsKeyLabel = filterInputs.reduce(
                (keysLabel, input) => ({
                    ...keysLabel,
                    [input.name]: input.label,
                }),
                {},
            );

            return filterKeys.map(key => inputsKeyLabel[key]);
        }, [filterInputs, pageId]);

        const initializeSelectedFilters = useCallback(() => {
            if (!filterInputs) return;

            let initialSelectedFilters;
            const storedSelectFilters = BrowserStorageHelper.loadFiltersConfig(pageId);

            if (storedSelectFilters) {
                initialSelectedFilters = storedSelectFilters;
            } else {
                initialSelectedFilters = filterInputs
                    .filter(filterDefaultSelectedFilter)
                    .map(mapToFilterLabel);
            }

            const selectedFiltersFromParam = getSelectedFiltersFromRequestParam();
            if (selectedFiltersFromParam.length) {
                initialSelectedFilters = _union(
                    initialSelectedFilters,
                    selectedFiltersFromParam,
                );
            }

            setSelectedFilters(initialSelectedFilters);
            setHasFilterConfigLoaded(true);
        }, [filterInputs, getSelectedFiltersFromRequestParam, pageId]);

        const initializeListDataAndForm = useCallback(hasVariableFilters => {
            const {
                filters, orders, page, limit,
            } = getStoredRequestParams(pageId);

            const pagination = {
                page: page || 1,
                limit: limit || DEFAULT_PAGE_SIZE,
            };

            const params = {
                filters: {
                    ...defaultFilters,
                    ...filters,
                },
                orders: {
                    ...defaultOrders,
                    ...orders,
                },
            };

            if (!areObjectsEqual(params.filters, defaultFilters)) {
                setShowAdvancedFilter(true);
            }

            setTablePagination(pagination);
            setRequestParams(params);

            form.initialize(params.filters || {});

            /*
            * Adia a submissão do Form para dar tempo do State ser atualizado.
            */
            setTimeout(() => {
                form.submit();

                // Adiciona um campo de filtro nos filtros dinâmicos, quando a tela monta e não tem nenhum filtro.
                if (hasVariableFilters && !params.filters?.variableFilters?.length) {
                    form.change('variableFilters', [{
                        logicalOperator: LOCAL_OPERATOR_OPTIONS[0],
                    }]);
                }
            });
        }, [defaultFilters, defaultOrders, form, pageId]);

        const initializeListConfig = useCallback(async () => {
            if (!requestListConfig) return false;

            try {
                setRequestingListConfig(true);
                const variableFilters = await requestListConfig();
                setVariableFiltersOptions(variableFilters);
                return true;
            } catch (err) {
                console.warn(error);
                return false;
            } finally {
                setRequestingListConfig(false);
            }
        }, [requestListConfig]);

        const initializeData = useCallback(async () => {
            const hasVariableFilters = await initializeListConfig();
            initializeSelectedFilters();
            initializeSelectedColumns();
            initializeListDataAndForm(hasVariableFilters);
        }, []);

        useDidMount(() => {
            initializeData();
        });

        const hasChangedFilters = useMemo(() => {
            return !areObjectsEqual(requestParams.filters, defaultFilters);
        }, [requestParams.filters, defaultFilters]);

        const onFilterSubmit = useCallback(
            async values => {
                const params = {
                    ...requestParams,
                    filters: values,
                };

                const pagination = {
                    ...tablePagination,
                    page: 1,
                    limit: tablePagination.limit,
                };

                setRequestParams(params);
                setTablePagination(pagination);

                const response = await sendDataSourceRequest(params, pagination);

                BrowserStorageHelper.saveFilters(pageId, {
                    ...params,
                    limit: pagination.limit,
                    page: pagination.page,
                });

                return response;
            },
            [requestParams, tablePagination, sendDataSourceRequest, pageId],
        );

        const handleClearFilters = useCallback(async () => {
            const params = {
                filters: defaultFilters,
                orders: defaultOrders,
            };

            setTablePagination(DEFAULT_TABLE_PAGINATION);
            setRequestParams(params);
            setShowAdvancedFilter(false);

            form.initialize(defaultFilters || {});

            const response = await sendDataSourceRequest(
                params,
                DEFAULT_TABLE_PAGINATION,
            );

            BrowserStorageHelper.saveFilters(pageId, {
                filters: null,
                orders: null,
                limit: DEFAULT_TABLE_PAGINATION.limit,
                page: DEFAULT_TABLE_PAGINATION.page,
            });

            return response;
        }, [defaultFilters, defaultOrders, sendDataSourceRequest, pageId, form]);

        useImperativeHandle(
            $ref,
            () => ({
                refresh: () => sendDataSourceRequest(requestParams, tablePagination),
                refilter: filters => {
                    const newFilters = {
                        ...defaultFilters,
                        ...filters,
                    };
                    form.initialize(newFilters);
                    return form.submit();
                },
                getFilters: () => requestParams.filters,
                getOrders: () => requestParams.orders,
            }),
            [
                defaultFilters,
                form,
                requestParams,
                sendDataSourceRequest,
                tablePagination,
            ],
        );

        const renderConfigureColumns = useMemo(() => {
            if (isRenderingCustomContent || !configurable) {
                return null;
            }

            return (
                <Popover
                    placement="bottom"
                    title={t('SELECT_COLUMNS')}
                    content={(
                        <CheckboxGroup
                            value={selectedColumns}
                            onChange={onColumnsConfigChanged}
                        >
                            {columnsTitles.map(colTitle => (
                                <div key={colTitle}>
                                    <Checkbox value={colTitle}>{colTitle}</Checkbox>
                                </div>
                            ))}
                        </CheckboxGroup>
                    )}
                    trigger="click"
                    disabled={isLoading}
                >
                    <Button
                        icon={<ProjectOutlined style={ICON_STYLE} />}
                        disabled={isLoading}
                        title={t('HIDE_SHOW_COLUMNS')}
                        fontColor={null}
                    />
                </Popover>
            );
        }, [isRenderingCustomContent, configurable, selectedColumns, isLoading]);

        const renderConfigureFilters = useMemo(() => {
            if (!hasFilterConfigLoaded) return null;

            return (
                <ConfigureFilterOption
                    options={filterOptions}
                    initialValues={selectedFilters}
                    disabled={isLoading}
                    selectedValues={selectedFilters}
                    onChangeValues={onFilterInputsConfigChange}
                />
            );
        }, [hasFilterConfigLoaded, filterOptions, selectedFilters, isLoading]);

        const renderPrintButton = useMemo(() => {
            if (isRenderingCustomContent || !printable) {
                return null;
            }

            return (
                <Button
                    icon={<PrinterOutlined style={ICON_STYLE} />}
                    onClick={onPrintTableClick}
                    loading={printing}
                    fontColor={null}
                    disabled={isLoading || !dataSource.length}
                    title={t('PRINT_RESULT')}
                />
            );
        }, [isRenderingCustomContent, printing]);

        const renderFilters = useMemo(() => {
            if (variableFiltersOptions?.length) {
                return (
                    <FilterContainer
                        loading={isLoading}
                        onClearClick={hasChangedFilters ? handleClearFilters : null}
                    >
                        <VariableFilterFieldArray
                            name="variableFilters"
                            typeOptions={variableFiltersOptions}
                        />
                    </FilterContainer>
                );
            }

            if (filterInputs) {
                const filteredInputs = filterInputs
                    .filter(input => {
                        const { label } = input;
                        return Boolean(selectedFilters.find(sel => sel === label));
                    })
                    .map(input => {
                        const { column, name, component } = input;
                        const isFunction = typeof component === 'function';

                        return (
                            <Col {...column} key={name}>
                                {isFunction ? component(form) : component}
                            </Col>
                        );
                    });

                const showClearButton = hasChangedFilters && filteredInputs.length;

                return (
                    <FilterContainer
                        loading={isLoading}
                        onClearClick={showClearButton ? handleClearFilters : null}
                    >
                        <Row gutter={24} align="middle">
                            {filteredInputs}
                        </Row>
                    </FilterContainer>
                );
            }

            return (
                <>
                    {basicFilter ? (
                        <FilterContainer
                            loading={isLoading}
                            onClearClick={hasChangedFilters ? handleClearFilters : null}
                        >
                            {basicFilter}
                        </FilterContainer>
                    ) : null}

                    {advancedFilter ? (
                        <Collapse
                            bordered={false}
                            className={styles.advancedFilterCollapse}
                            activeKey={shouldShowAdvancedFilter ? '1' : '0'}
                            onChange={handleAdvancedFilterChange}
                        >
                            <Panel header={t('ADDITIONAL_FILTERS')} key="1">
                                {advancedFilter}
                            </Panel>
                        </Collapse>
                    ) : null}
                </>
            );
        }, [
            hasChangedFilters, isLoading, variableFiltersOptions,
            filterInputs, selectedFilters, basicFilter, advancedFilter,
            shouldShowAdvancedFilter, t,
        ]);

        return (
            <ContentPage
                {...others}
                title={pageTitle}
                extra={(
                    <>
                        {renderConfigureColumns}
                        {renderConfigureFilters}
                        {renderPrintButton}
                        {extraHeaderContent}
                    </>
                )}
            >
                <Alert
                    type="success"
                    {...returningAlert}
                    onClose={onCloseReturningAlert}
                />
                <Alert type="error" message={error || submitError} />

                <Form
                    onSubmit={handleSubmit(onFilterSubmit)}
                    className={styles.formFilter}
                >
                    {renderFilters}
                </Form>

                {renderContent ? (
                    renderContent(dataSource, tablePagination, isLoading)
                ) : (
                    <Table
                        loading={isLoading}
                        dataSource={dataSource}
                        total={tablePagination.total}
                        limit={tablePagination.limit}
                        page={tablePagination.page}
                        onChange={onTableChange}
                        rowKey={tableRowKey}
                        scroll={tableScroll}
                        rowSelection={tableRowSelection}
                        expandable={tableExpandable}
                        pagination={showPagination}
                    >
                        {filteredChildren}
                    </Table>
                )}
            </ContentPage>
        );
    },
);

ListPageContent.propTypes = {
    id: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    requestDataSource: PropTypes.func.isRequired,
    defaultFilters: PropTypes.object,
    defaultOrders: PropTypes.shape({
        field: PropTypes.string.isRequired,
        order: PropTypes.oneOf(['descend', 'ascend']).isRequired,
    }),
    tableRowKey: PropTypes.func,
    tableScroll: PropTypes.object,
    requestListConfig: PropTypes.func,
};

ListPageContent.defaultProps = {
    loading: false,
    defaultFilters: undefined,
    filterInputs: undefined,
    defaultOrders: undefined,
    tableRowKey: item => item.id,
    tableScroll: {
        x: 1024,
    },
};

export default wrapForm(ListPageContent);
