/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useRef, useState } from 'react';

import { HistoryOutlined } from '@ant-design/icons';
import { ConfigProvider, Empty } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AdDescriptionColumn from '~/components/AdDescriptionColumn/AdDescriptionColumn';
import Icon from '~/components/Icon';
import IconButton from '~/components/IconButton';
import ListPageContent from '~/components/ListPageContent';
import PricingRuleActionsButton from '~/components/PricingRuleActionsButton';
import Table from '~/components/Table';
import UpdateSituationTag from '~/components/UpdateSituationTag';
import {
    formatFieldArrayConfig,
    formatFieldArrayToSend,
} from '~/components/VariableFilterFieldArray/utils';
import { formatDate } from '~/helpers/date-helper';
import { maskMoney } from '~/helpers/mask-helper';
import useAxios from '~/hooks/use-axios';

import PricingHistoryDialog from '../dialog/PricingHistoryDialog';
import PricingRuleDialog from '../dialog/PricingRuleDialog';
import PageTitle from '../viewRecommendation/components/PageTitle';
import styles from './PricingUpdateHistoryPage.module.scss';

const { Column } = Table;

const PricingHistoryButton = ({ t, item, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(item);
    }, [onClick, item]);

    return (
        <div className={styles.actions}>
            <IconButton
                onClick={handleClick}
                Icon={HistoryOutlined}
                title={t('UPDATE_HISTORY')}
            />
        </div>
    );
};

const PricingUpdateHistoryPage = () => {
    const axios = useAxios({
        baseURL: process.env.REACT_APP_URL_API_GO,
    });
    const $ref = useRef(null);
    const [hasSkuFilter, setHasSkuFilter] = useState(false);

    const { t } = useTranslation('UPDATE_HISTORY_PAGE');

    const requestDataSource = useCallback(async (filterValues, orderValues, page, limit) => {
        const params = {
            page,
            limit,
        };

        filterValues.variableFilters?.forEach(filter => {
            if (filter.type.key === 'l.sku' || filter.type.key === 'l.sku_marketplace') {
                setHasSkuFilter(true);
            }
        });

        const formattedFilters = formatFieldArrayToSend(filterValues.variableFilters);

        const response = await axios.post('/v1/rules/query', {
            params,
            filters: formattedFilters,
        });

        return response;
    }, [axios]);

    const requestListConfig = useCallback(async () => {
        const response = await axios.get('/v1/rules/schemas');

        return formatFieldArrayConfig(response.data.allowedFields);
    }, [axios]);

    const renderEmpty = useCallback(() => {
        let description;

        if (hasSkuFilter) {
            description = (
                <div>{t('VERIFY_ADS_FOR_SKU')}</div>
            );
        }

        return <Empty description={description} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }, [hasSkuFilter, t]);

    const maskUpdate = useCallback(update => {
        if (!update) return '';
        return formatDate(update, 'DD/MM/YYYY HH:mm');
    }, []);

    const renderMoneyColumn = useCallback((value, data) => {
        // TODO: Remover quando for ajustado retorno da API
        const money = maskMoney(value, { ...data, currencyCode: 'BRL', countryCode: 'BR' });
        return (
            <div className={styles.productPriceColumn}>
                {money}
            </div>
        );
    }, []);

    const renderUpdateType = useCallback((_, item) => {
        switch (item.eventType) {
            case 'changed_price_to_win_buybox':
                return (
                    <span className={classNames([styles.updateType, styles.down])}>
                        {t('CHANGED_PRICE_TO_WIN_BUYBOX')}
                        <Icon family="MaterialIcons" name="arrow_downward" size={15} />
                    </span>
                );
            case 'changed_price_increase_margin':
                return (
                    <span className={classNames([styles.updateType, styles.up])}>
                        {t('CHANGED_PRICE_INCREASE_MARGIN')}
                        <Icon family="MaterialIcons" name="arrow_upward" size={15} />
                    </span>
                );
            default:
                return null;
        }
    }, [t]);

    const renderRuleStrategy = useCallback((_, item) => {
        switch (item.ruleStrategy) {
            case 'buybox_to_win':
                return t('BUYBOX_TO_WIN');
            case 'buybox_to_win_exceptions':
                return t('BUYBOX_TO_WIN_EXCEPTIONS');
            case 'competitor_ranking_win':
                return t('COMPETITOR_RANKING_WIN');
            default:
                return null;
        }
    }, [t]);

    const renderUpdateSituation = useCallback((_, data) => {
        return <UpdateSituationTag data={data} />;
    }, []);

    const openPricingHistoryDialog = useCallback(data => {
        PricingHistoryDialog.show({
            data,
            maskUpdate,
            renderMoneyColumn,
            renderRuleStrategy,
            renderUpdateSituation,
        });
    }, [maskUpdate, renderMoneyColumn, renderRuleStrategy, renderUpdateSituation]);

    const openPricingRuleDialog = useCallback(data => {
        PricingRuleDialog.show({ data, onCancelPricingRuleDialog: $ref.current.refresh });
    }, []);

    const renderUpdateAction = useCallback((_, data) => {
        return (
            <div className={styles.actions}>
                <PricingRuleActionsButton
                    data={data}
                    onOpenModal={() => openPricingRuleDialog(data)}
                    onSubmit={$ref.current.refresh}
                />
                <PricingHistoryButton
                    t={t}
                    data={data}
                    onClick={() => openPricingHistoryDialog(data)}
                />
            </div>
        );
    }, [openPricingHistoryDialog, openPricingRuleDialog, t]);

    return (
        <>
            <ConfigProvider renderEmpty={renderEmpty}>
                <ListPageContent
                    configurable
                    ref={$ref}
                    id="pricing-update-history"
                    title={(
                        <PageTitle
                            title={t('PAGE_TITLE')}
                            subtitle={t('PAGE_SUBTITLE')}
                        />
                    )}
                    requestListConfig={requestListConfig}
                    requestDataSource={requestDataSource}
                >
                    <Column
                        title={t('COLUMN_TITLE_UPDATED_AT')}
                        key="lastUpdate"
                        dataIndex="lastUpdate"
                        render={maskUpdate}
                        align="center"
                        width={120}
                    />
                    <Column
                        title={t('COLUMN_TITLE_AD_TITLE')}
                        key="title"
                        dataIndex="title"
                        render={(_, adData) => <AdDescriptionColumn adData={adData} />}
                        align="center"
                        width={420}
                    />
                    <Column
                        title={t('COLUMN_TITLE_RIVAL_PRICE')}
                        key="rivalPrice"
                        dataIndex="rivalPrice"
                        render={renderMoneyColumn}
                        csvRender={renderMoneyColumn}
                        align="center"
                        width={120}
                    />
                    <Column
                        title={t('COLUMN_TITLE_PREVIOUS_PRICE')}
                        key="lastPrice"
                        dataIndex="lastPrice"
                        render={renderMoneyColumn}
                        csvRender={renderMoneyColumn}
                        align="center"
                        width={120}
                    />
                    <Column
                        title={t('COLUMN_TITLE_NEW_PRICE')}
                        key="currentPrice"
                        dataIndex="currentPrice"
                        render={renderMoneyColumn}
                        csvRender={renderMoneyColumn}
                        align="center"
                        width={120}
                    />
                    <Column
                        title={t('COLUMN_TITLE_STRATEGY')}
                        key="ruleStrategy"
                        dataIndex="ruleStrategy"
                        render={renderRuleStrategy}
                        align="center"
                        width={120}
                    />
                    <Column
                        title={t('COLUMN_TITLE_UPDATE_TYPE')}
                        key="eventType"
                        dataIndex="eventType"
                        render={renderUpdateType}
                        align="center"
                        width={150}
                    />
                    <Column
                        title={t('COLUMN_TITLE_SITUATION')}
                        key="logStatus"
                        dataIndex="logStatus"
                        render={renderUpdateSituation}
                        align="center"
                        width={100}
                    />
                    <Column
                        title={t('COLUMN_TITLE_ACTIONS')}
                        key="action"
                        render={renderUpdateAction}
                        align="center"
                        width={120}
                    />
                </ListPageContent>
            </ConfigProvider>
        </>
    );
};

export default PricingUpdateHistoryPage;
