import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import styles from './TextHelper.module.scss';

const TextHelper = ({ tooltipTitle, children }) => {

    return (
        <Tooltip
            title={tooltipTitle}
        >
            {children}
            <QuestionCircleOutlined className={styles.tooltipIcon} />
        </Tooltip>
    );
};

export default TextHelper;
