import React, { useState } from 'react';

import { Layout } from 'antd';
import { connect } from 'react-redux';

import useDidMount from '~/hooks/use-did-mount';
import i18n from '~/translations';

import AppHeaderBar from './AppHeaderBar';
import styles from './AppLayout.module.scss';
import AppRoutes from './AppRoutes';
import AppSideMenu from './AppSideMenu';

const { Content } = Layout;

const AppLayout = ({
    history,
}) => {

    const [ready, setReady] = useState(false);

    // const navigateToLanding = () => {
    //     history.push('/');
    // };

    // const navigateToLogin = () => {
    //     history.push('/login');
    // };

    // const refreshToken = async () => {
    //     try {
    //         await refreshUserAuthentication();
    //         setReady(true);
    //     } catch (ex) {
    //         console.warn(ex);
    //         const { response } = ex;
    //         if (!response) {
    //             navigateToLanding();
    //         } else {
    //             navigateToLogin();
    //         }
    //     }
    // };

    useDidMount(() => {
        // if (!skipRefreshToken) {
        // refreshToken();
        // } else {
        setReady(true);
        // }
    });

    if (!ready) {
        return i18n.t('APP_LAYOUT:UPDATE_ACCESS_AUTHORIZATION');
    }

    const integrationRoutes = [
        '/app/integration/amazon',
        '/app/integration/mercadolivre',
    ];

    let defaultLayout = (
        <div className={styles.container}>
            <Layout className={styles.layout}>
                <AppSideMenu />
                <Content className={styles.content}>
                    <AppHeaderBar />
                    <AppRoutes />
                </Content>
            </Layout>
        </div>
    );

    if (integrationRoutes.includes(history.location.pathname)) {
        defaultLayout = (
            <div className={styles.container}>
                <Layout className={styles.layout}>
                    {/* <AppSideMenu /> */}
                    <Content className={styles.content}>
                        <AppHeaderBar />
                        <AppRoutes />
                    </Content>
                </Layout>
            </div>
        );
    }

    return defaultLayout;
};

// const mapStateToProps = ({ skipRefreshToken }) => ({
//     skipRefreshToken,
// });

// const mapDispatchToProps = {
//     refreshUserAuthentication: thunks.user.refreshUserAuthentication,
// };

export default connect(null, null)(AppLayout);
