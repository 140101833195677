import React, {
    useState, useCallback, memo,
} from 'react';

import { MenuFoldOutlined } from '@ant-design/icons';
import { PageHeader, Button } from 'antd';

import useClassNames from '~/hooks/use-classnames';
import useScreen from '~/hooks/use-screen';

import SidePageContent from './SidePageContent';
import styles from './styles.module.scss';

const ContentPage = memo(({
    children, extra, sider,
    containerClassName, siderWidth,
    ...others
}) => {
    const { sizes: { isDesktop, isMobile } } = useScreen();
    const [shouldShowSider, setShowSider] = useState(false);

    const onToggleSiderClick = useCallback(() => {
        setShowSider(prev => !prev);
    }, []);

    const renderSiderToggleButton = () => {
        if (!sider || isDesktop) {
            return null;
        }

        return (
            <Button
                type="dashed"
                icon={<MenuFoldOutlined />}
                onClick={onToggleSiderClick}
                className={styles.siderToggleButton}
                size="large"
            />
        );
    };

    const containerClasses = useClassNames([styles.mainContainer, containerClassName]);
    const pageHeaderClasses = useClassNames([styles.pageHeader, {
        [styles.pageHeaderWithSider]: Boolean(sider),
    }]);

    return (
        <div className={containerClasses}>
            <PageHeader
                {...others}
                extra={(
                    <>
                        <div className={styles.extraContainer}>
                            {extra}
                        </div>
                        {renderSiderToggleButton()}
                    </>
                )}
                ghost={!isMobile}
                className={pageHeaderClasses}
            >
                <div className={styles.pageHeaderContent}>
                    {children}
                </div>
            </PageHeader>

            {sider ? (
                <SidePageContent
                    width={siderWidth}
                    className={styles.sider}
                    visible={shouldShowSider}
                    isDesktop={isDesktop}
                    onClose={onToggleSiderClick}
                >
                    {sider}
                </SidePageContent>
            ) : null}
        </div>
    );
});

export default ContentPage;
